import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Slider from 'react-slick';

import { Link } from 'react-router-dom';

import Breadcrumb from "../../template/breadcrumb";
import {Slider2, Team4} from "../../../services/script";

const PrivacyPage = ({ }) => {

  return (
    <div>
        <Breadcrumb title="Privacy"/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12">
                        <h2><span className="green-text">PRIVACY</span> POLICY</h2>
                          <div>
                              <p>This policy is designed to inform you of our commitment to your right to privacy when using our website (fireworxscotland.co.uk)</p>
                              <p>We will treat all your personal information as confidential although we reserve the right to disclose this information in the circumstances set out below. All your details are held on a secure server and we fully comply with the UK Data Protection Act 1998.</p>
                          </div>

                          <div>
                            <h4>What personal information do we collect?</h4>
                            <p>When you shop on this website, we will ask you to tell us some personal information such as your name, e-mail address, billing address, delivery address, telephone number, product selections, payment information and a password. We may also collect some technical information eg how you found our website, the pages of our website that were viewed during your visit, the advertisements you clicked on, and any search terms that you entered on our website.</p>
                            <p>We confirm that any personal information which you provide to us (or which is available on public registers) and any information from which we can identify you, is held in accordance with the registration we have with the Data Commissioner's Office.</p>
                          </div>

                          <div>
                            <h4>What do we do with this information</h4>
                            <p>The information we collect depends on the services on our website that you use.</p>
                            <p><strong>If you do not make a purchase, we monitor your website visit:</strong></p>
                            <ol>
                              <li>To help us improve the quality of our service</li>
                              <li>To serve appropriate adverts and product placements</li>
                              <li>To allow you access to add your own comments/content to our website</li>
                            </ol>

                            <p><strong>If you make a purchase on our site, we collect your details:</strong></p>
                            <ol>
                              <li>To process your order(s)</li>
                              <li>To serve appropriate content and advertisements to you</li>
                              <li>To administer the website</li>
                              <li>To notify you of special offers that may be of interest to you</li>
                            </ol>
                            <p>By using our website you agree that you do not object to us contacting you for any of the above purposes whether by telephone, e-mail or in writing and you confirm that you do not and will not consider any of the above as being a breach of any of your rights under the Privacy and Electronic Communications (EC Directive) Regulations 2003. </p>
                          </div>

                          <div>
                            <h4>Will you send me junk mail or unsolicited email (spam)? </h4>
                            <p>When you create an account or make a purchase on our site, you will be given the option to receive 'mailing-list' information from us. We will normally only contact you by e-mail, however we may need to contact by post, or telephone, about your orders, or any promotions/special offers which we feel may be of interest to you. In the event that you do not wish to be contacted for such purposes, ensure that you tick the appropriate box as you create your account. You may unsubscribe from our mailing list at any time by logging into your account.</p>
                          </div>

                          <div>
                            <h4>What information do you give to 3rd Parties? </h4>
                            <p>Your personal information may be disclosed to reputable third parties who will help process your order. For example - we usually need to hand your address to Royal Mail for the delivery of your goods.</p>
                            <p>This will only be done when relevant to the manufacture or shipping of your order. We will not release your personal information to any company for mailing or marketing purposes.</p>
                            <p>You should be aware that if we are requested by the police or any other regulatory or government authority investigating suspected illegal activities to provide your personal information we are entitled do so.</p>
                          </div>

                          <div>
                            <h4>What about 'Cookies' on our Website?</h4>
                            <p>A cookie is a piece of text that a website transfers to a directory on your computer's hard disk, so that the website can remember who you are. This text file is known as a `cookie`. Most website use cookies. </p>
                            <p>A cookie will typically contain the name of the website from which the cookie has come, the "lifetime" of the cookie, and a value, usually a randomly generated unique number. Allowing a website to create a cookie does not give that or any other website access to the rest of your computer, and only the site that created the cookie can read it.</p>
                            <p>We use Cookies to keep track of who you are when you are adding products to your shopping basket. We may also use this information to personalise the website for you. You can set your browser to not accept Cookies from our website however you may find that you are unable to use our website without them. </p>
                          </div>

                          <div>
                            <h4>I still have unanswered questions - what happens now?</h4>
                            <p>Our Privacy Policy is updated frequently. Any further questions can be answered by telephone, email, or post - you will find our <Link to={`${process.env.PUBLIC_URL}/contact-us`} className="green-text">contact details here</Link></p>
                          </div>

                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

PrivacyPage.propTypes = {
  fetchPage: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired
};


export default connect(null, {  })(PrivacyPage)
