import React from 'react';

import { Link } from 'react-router-dom';

import Breadcrumb from "../../../template/breadcrumb";
import { Helmet } from 'react-helmet'

const CarsPage = ({ }) => {

  return (
    <div>
        <Breadcrumb title="Wedding Cars"/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12">
                        <h2>WEDDING <span className="green-text">CARS</span></h2>

                          <div>
                            <h4>ARRIVE IN STYLE</h4>

                            <table className="table cart-table table-responsive-xs">
                              <tr>
                                <td><img src={`https://fireworxscotland.co.uk/wp-content/uploads/2014/08/rainbow-prestige.png`} /></td>
                                <td>
                                  <h4>RAINBOW <span className="green-text">PRESTIGE</span></h4>
                                  <h5 className="purple-text">COMFORT & ELEGANCE</h5>
                                  <p>Rainbow Prestige one the largest premier chauffeur drive and wedding car companies in Scotland. We provide a professional corporate, wedding and special events service which we believe is second to none and we pride ourselves on our professionalism and customer care. Every year our fleet and range of chauffeur cars is increasing and improving to meet customer demands and satisfaction, and we have recently added another 5 new vehicles to our growing fleet, 2 Mercedes S Class, Audi A8 Executive Sport, Audi Q7 S Line and Audi A6 S Line. Our customers return to us time and time again due to the very high quality and standards of our chauffeur cars and service.</p>
                                </td>
                              </tr>

                            </table>

                          </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}



export default CarsPage;
