import React, { useEffect, useState } from 'react';
import Breadcrumb from "../template/breadcrumb";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const Dashboard = ({ auth: { user } }) => {

  useEffect(() => {
    console.log('hello')
  })

  return (
      <div>
          <Breadcrumb title={'Dashboard'}/>


          {/*Dashboard section*/}
          <section className="section-b-space">
              <div className="container">
                  <div className="row">
                      <div className="col-lg-3">
                          <div className="account-sidebar">
                              <a className="popup-btn">
                                  my account
                              </a>
                          </div>
                          <div className="dashboard-left">
                              <div className="collection-mobile-back">
                              <span className="filter-back">
                                  <i className="fa fa-angle-left" aria-hidden="true"></i> back
                              </span>
                              </div>
                              <div className="block-content">
                                  <ul>
                                      <li className="active">Account Info</li>
                                      <li><Link to={`${process.env.PUBLIC_URL}/account/orders/my`}>My Orders</Link></li>
                                      <li><Link to={`${process.env.PUBLIC_URL}/wishlist`}>My Wishlist</Link></li>
                                      <li><a href="#">Change Password</a></li>
                                      <li className="last"><a href="#">Log Out</a></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-9">
                          <div className="dashboard-right">
                              <div className="dashboard">
                                  <div className="page-title">
                                      <h2>My Dashboard</h2>
                                  </div>
                                  <div className="welcome-msg">
                                      <p>Hello, {user.firstName} {user.lastName} !</p>
                                      <p>From your My Account Dashboard you have the ability to view a snapshot of
                                          your recent account activity and update your account information. Select
                                          a link below to view or edit information.</p>
                                  </div>
                                  <div className="box-account box-info">
                                      <div className="box-head">
                                          <h2>Account Information</h2>
                                      </div>
                                      <div className="row">
                                          <div className="col-sm-6">
                                              <div className="box">
                                                  <div className="box-title">
                                                      <h3>Contact Information</h3>
                                                  </div>
                                                  <div className="box-content">
                                                      <h6>{user.firstName} {user.lastName}</h6>
                                                      <h6>{user.email}</h6>
                                                      <h6><a href="#">Change Password</a></h6>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="col-sm-6">
                                              <div className="box">
                                                  <div className="box-title">
                                                      <h3>Newsletters</h3>
                                                  </div>
                                                  <div className="box-content">
                                                      <p>
                                                          You are currently not subscribed to any newsletter.
                                                      </p>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

      </div>
  )
}

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps)(Dashboard)
