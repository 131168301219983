import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';

import Breadcrumb from "../template/breadcrumb";
import { Helmet } from 'react-helmet'

import { connect } from 'react-redux';
import { fetchMyOrders } from '../../actions/orders';

const MyOrders = ({ fetchMyOrders, orders }) => {

  useEffect(() => {
    fetchMyOrders();
  }, [fetchMyOrders]);

  return (
    <div>
        <Breadcrumb title="Orders"/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12">
                        <h2>MY <span className="green-text">ORDERS</span></h2>

                          <div>
                            <h4>YOUR ORDERS WITH FIREWORX SCOTLAND</h4>

                            <table className="table cart-table table-responsive-xs">
                            <thead>
                            <tr className="table-head">
                                <th scope="col">Date</th>
                                <th scope="col">Shipping Address</th>
                                <th scope="col">Shipping Cost</th>
                                <th scope="col">Total</th>
                                <th scope="col">Status</th>
                            </tr>
                            </thead>

                            {orders.map((order, index) => {
                              let createdAt = '';
                              if(order.createdAt){
                                createdAt = new Date(order.createdAt).toLocaleString();
                              }
                              return(

                                <tr key={index}>
                                  <td>{createdAt}</td>
                                  <td>Shipping here</td>
                                  <td>£{order.shippingCost/100}</td>
                                  <td>£{order.total/100}</td>

                                </tr>
                              )
                            })}




                            </table>

                          </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

const mapStateToProps = (state) => {
    return {
      orders: state.order.orders
    }
}

export default connect(mapStateToProps, { fetchMyOrders })(MyOrders);
