import React from 'react';

import { Link } from 'react-router-dom';

import Breadcrumb from "../../../template/breadcrumb";
import { Helmet } from 'react-helmet'

const SafetyPage = ({ }) => {

  return (
    <div>
        <Breadcrumb title="Health and Safety"/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12">
                        <h2>FIREWORKS <span className="green-text">SAFETY</span></h2>

                          <div>
                            <h4>THE SAFE USE OF FIREWORKS</h4>

                          </div>
                          <div>
                            <h4>HEALTH & <span className="green-text">SAFETY</span></h4>
                            <h5 className="purple-text">WE TAKE IT SERIOUSLY</h5>
                            <p>Fireworx Scotland is a member of the CBI Explosives Group and past members of the BPA.</p>
                            <p>Fireworx Scotland has a staff of 42 fully trained pyrotechnicians that work for us on a part time basis. All our staff are trained to BPA standard.</p>
                            <p>We have an HSE Statement.</p>
                            <p>Every display we undertake is fully risk assessed prior to and during the event</p>
                            <p>We have a Public and Product Liability Insurance Cover of £10,000,000.</p>
                          </div>

                          <div>
                            <h4>FIREWORK <span className="green-text">SAFETY</span></h4>
                            <h5 className="purple-text">DON'T TAKE RISKS</h5>
                            <p>Hundreds of people are injured each year due to the misuse of fireworks. Please make sure you’re not one of them by being safe and responsible. Some points to remember are outlined below.</p>
                            <br />
                            <p>1. It is illegal to sell fireworks to children under 18 years old. They should not be allowed to set them off or play with them. Fireworks are NOT toys.</p>
                            <p>2. Beware of the wind. Stand upwind of the fireworks so that it will not blow the sparks and debris in your direction or anyone else’s. The larger and flatter the area the better.</p>
                            <p>3. Stand well back. The surprise element of fireworks is half their attraction and you can never be sure how far they will shoot or spray. Stand AT LEAST the recommended distance away if not further.</p>
                            <p>4. Never go back to a firework once it is lit. This applies to anyone. Even if you can no longer see burning the flame may be inside and ready to explode. Better to wait and be safe than impatient and sorry.</p>
                            <p>5. Keep pets indoors. With their black and white vision and extremely sensitive hearing, animals just can’t appreciate fireworks the way we do. In fact, they hate them. Keep them securely indoors so they can’t spoil it for, or endanger, anyone else.</p>
                            <p>6. Never pick up a sparkler once dropped. A sparkler can be great fun but, once it is finished, drop it in a puddle of water and leave it there until the next morning. They are designed to get very very hot and people picking up the wrong end of one is one of the main causes of injury due to fireworks.</p>
                            <p>7. Never put fireworks in your pocket.</p>
                            <p>8. Fire from level ground with the firework or rocket tube safely secured in an upright position.</p>
                            <p>9. Store fireworks in an airtight container for example an empty biscuit tin.</p>
                          </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}



export default SafetyPage;
