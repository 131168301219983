import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Slider from 'react-slick';

import { Link } from 'react-router-dom';

import Breadcrumb from "../../template/breadcrumb";
import {Slider2, Team4} from "../../../services/script";

const CookiesPage = ({ }) => {

  return (
    <div>
        <Breadcrumb title="Cookie Policy"/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12">
                        <h2><span className="green-text">COOKIE</span> POLICY</h2>
                          <div>
                            <h4>What are 'Cookies'?</h4>
                            <p>This policy is designed to inform you of our commitment to your right to privacy and details of how we use cookies using our website (fireworxscotland.co.uk).</p>
                            <p>A cookie is a piece of text that a website transfers to a directory on your computer's hard disk, so that the website can remember who you are. This text file is known as a `cookie`. Most website use cookies. </p>
                            <p>A cookie will typically contain the name of the website from which the cookie has come, the "lifetime" of the cookie, and a value, usually a randomly generated unique number. Allowing a website to create a cookie does not give that or any other website access to the rest of your computer, and only the site that created the cookie can read it.</p>
                            <p>We use Cookies to keep track of who you are when you are adding products to your shopping basket. We may also use this information to personalise the website for you. You can set your browser to not accept Cookies from our website however you may find that you are unable to use our website without them. </p>
                          </div>

                          <div>
                            <h4>Advertising and Tracking Cookies</h4>
                            <p>We do not currenly allow advertising from any other organisation on our websites. We do advertise our own products on our own website and you may see adverts for us elsewhere on the internet.</p>
                            <p>The cookies we use to present you with adverts may be based on the content you visit the most on our website, so we can let you know about what we think you will be interested in.</p>
                          </div>

                          <div>
                            <h4>Google, Analytics and Remarketing Technology</h4>

                            <p>We use Google Analytics, a service provided by Google Ireland Limited. Google Analytics uses cookies to help analyse how users use our websites. The information generated by the cookies about your use of our websites and your current IP-address will be transmitted by your browser to and will be stored by Google on servers in the United States and other countries.</p>
                            <p>We also use Google's remarketing technology. In doing so, Google will place or read a unique cookie on your computer and will use non-personal information about your browser and your activity on our sites to serve ads on their network.</p>
                          </div>

                          <div>
                            <h4>I still have unanswered questions - what happens now?</h4>
                            <p>Our policies are updated frequently and contains more information. Any further questions can be answered by telephone, email, or post - you will find our <Link to={`${process.env.PUBLIC_URL}/contact-us`} className="green-text">contact details here</Link></p>
                          </div>



                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

CookiesPage.propTypes = {
  fetchPage: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired
};


export default connect(null, {  })(CookiesPage)
