import {
  ORDER_SUCCESS_EMAIL,
  SIGNUP_EMAIL,
  NEWSLETTER_EMAIL,
  SUBMIT_CONTACT_FORM,
  ERROR
} from './types';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

// Send successful order email
export const orderSuccessEmail = ids => async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const body = JSON.stringify(ids);
    try{
      const response = await axios.post('/api/emails/order-success', body, config);
      dispatch({
        type: ORDER_SUCCESS_EMAIL
      });
    }catch(e){
      // Handle error
      dispatch({
        type: ERROR
      });
    }
};

// Subscribe to newsletter
export const newsletterSignup = email => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const body = JSON.stringify(email);
  try{
    const response = await axios.post('/api/users/newsletter', body, config);
    toast.success('Thank you for subscribing to our newsletter');
    dispatch({
      type: NEWSLETTER_EMAIL
    });
  } catch(e) {
    // Handle Error
    const errors = e.response.data.errors;
    if(errors){
      errors.forEach(error => toast.error(error.msg));
    }
    dispatch({ type: ERROR });
  }
}

// Submit contact form
export const submitContactForm = formDetails => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const body = JSON.stringify(formDetails);
  try {
    const response = await axios.post('/api/emails/contact-form', body, config);
    toast.success('Contact form submitted. Thank you!');
    dispatch({
      type: SUBMIT_CONTACT_FORM
    });
  } catch (e) {
    // Handle Errors
    const errors = e.response.data.errors;
    if(errors){
      errors.forEach(error => toast.error(error.msg));
    }
    dispatch({
      type: ERROR
    });
  }
}
