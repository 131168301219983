import React from 'react';

import Breadcrumb from "../../../template/breadcrumb";
import { Helmet } from 'react-helmet'

const CorporatePage = ({ }) => {

  return (
    <div>
    <Helmet>
        <title>Fireworx Scotland | Corporate Fireworks</title>
        <meta name="description" content="Whether you are launching a new business concept, a new retail branch, a new product or simply celebrating an achievement, Fireworx Scotland Ltd. has helped numerous local, national and international businesses celebrate their events with extra panache and style" />
    </Helmet>
        <Breadcrumb title="Corporate Fireworks"/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12">
                        <h2>CORPORATE <span className="green-text">FIREWORKS</span></h2>

                          <div>
                            <h4>THE SKY'S THE LIMIT</h4>

                            <p>Whether you are launching a new business concept, a new retail branch, a new product or simply celebrating
an achievement, Fireworx Scotland Ltd. has helped numerous local, national and international businesses celebrate their events with extra panache and style.</p>
                            <br/>

                            <p>Fireworx Scotland Ltd. work closely with our corporate clients’ production and marketing agencies to create fabulous shows that reflect the corporate identity and ethos.</p>
                            <br/>

                            <p>We have helped many new and existing businesses open new retail outlets, celebrate corporate events and launch new products with a spectacular firework display.</p>
                            <br/>

                            <p>Corporate displays can take many forms depending upon your objectives, from a traditional display with the emphasis on entertainment to an all action spectacular designed to impress with impact!</p>
                            <br/>

                          </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default CorporatePage;
