import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';

import Footer from "./template/footers/Footer";
import HeaderOne from "./template/headers/header-one";
import Home from './Home';
import Routes from './template/routes/Routes';

// Utilities
import store from '../store';
import { loadUser } from '../actions/auth';
import setAuthToken from '../utils/setAuthToken';

const App = () => {
  useEffect(() => {
    if(localStorage.token) {
      setAuthToken(localStorage.token);
      store.dispatch(loadUser());
    }
  }, []);

  return (

      <BrowserRouter>
      <ScrollContext>
        <Switch>

        <div>
            <Helmet>
                <title>Fireworx Scotland | Home</title>
            </Helmet>
            <HeaderOne logoName={'fireworx-logo.png'} />

          <Route exact path="/" component={Home} />
          <Route component={Routes} />

          <div className="footer">
              <Footer logoName={'logo/3.png'} />
          </div>
            <ToastContainer />
          </div>
        </Switch>
      </ScrollContext>
      </BrowserRouter>

  )
}

export default App;
