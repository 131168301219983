import React from 'react';

import { Link } from 'react-router-dom';

import Breadcrumb from "../../../template/breadcrumb";
import { Helmet } from 'react-helmet'

const EventPage = ({ }) => {

  return (
    <div>
        <Breadcrumb title="Event Management"/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12">
                        <h2>EVENT <span className="green-text">MANAGEMENT</span></h2>

                          <div>
                            <h4>A TAILORED SERVICE WITH A PERSONAL TOUCH</h4>

                            <table className="table cart-table table-responsive-xs">
                              <tr>
                                <td><img src={`https://fireworxscotland.co.uk/wp-content/uploads/2014/08/litu.jpg`} /></td>
                                <td>
                                  <h4>LITU <span className="green-text">EXCLUSIVE SCOTTISH WEDDINGS</span></h4>
                                  <h5 className="purple-text">FOR ALL THE RIGHT REASONS</h5>
                                  <p>Scottish Wedding Planners and Consultants Litu welcome you to our site – we are based in Scotland in the Kingdom of Fife close to St Andrews where we have been running our family company since 1997. Our aim is to succeed in giving you all the help and advice in planning your wedding whereby you make the decision and we do the legwork putting all your wishes into place, and in so doing we eliminate hours of research and stress attached in finding the right venue and suppliers to surround you on your special day. Visit their website</p>
                                </td>
                              </tr>

                              <tr>
                                <td><img src={`https://fireworxscotland.co.uk/wp-content/uploads/2014/08/primeevents.jpg`} /></td>
                                <td>
                                  <h4>PRIME <span className="green-text">EVENTS</span></h4>
                                  <h5 className="purple-text">EXCEED OUR CLIENTS EXPECTATIONS TIME AND TIME AGAIN</h5>
                                  <p>Scottish Wedding Planners and Consultants Litu welcome you to our site – we are based in Scotland in the Kingdom of Fife close to St Andrews where we have been running our family company since 1997. Our aim is to succeed in giving you all the help and advice in planning your wedding whereby you make the decision and we do the legwork putting all your wishes into place, and in so doing we eliminate hours of research and stress attached in finding the right venue and suppliers to surround you on your special day. Visit their website</p>
                                </td>
                              </tr>

                              <tr>
                                <td><img src={`https://fireworxscotland.co.uk/wp-content/uploads/2014/08/elitepromotions.jpg`} /></td>
                                <td>
                                  <h4>ELITE <span className="green-text">PROMOTIONS</span></h4>
                                  <h5 className="purple-text">WEDDING, CORPORATE DINNERS</h5>
                                  <p>We concentrate on quality not quantity and this site provides you with a very small selection of the entertainment we can offer. We mainly service the corporate and wedding markets and we are also the main supplier of entertainment to the major hotel groups in Aberdeen and throughout Scotland.</p>
                                </td>
                              </tr>

                              <tr>
                                <td><img src={`https://fireworxscotland.co.uk/wp-content/uploads/2014/08/entertainmentcompany.jpg`} /></td>
                                <td>
                                  <h4>THE ENTERTAINMENT <span className="green-text">COMPANY</span></h4>
                                  <h5 className="purple-text">WE CREATE WITH YOU, THEN MAKE IT HAPPEN</h5>
                                  <p>Scottish evenings, imaginative themes, glittering gala dinners, murder mysteries, crazy quizzes, team challenges, total décor schemes, corporate game shows, boogie on down or come to the concert, Scottish ceilidhs to Highland games, contemporary circus to customised silliness.</p>
                                </td>
                              </tr>

                              <tr>
                                <td><img src={`https://fireworxscotland.co.uk/wp-content/uploads/2014/08/valleyevents.jpg`} /></td>
                                <td>
                                  <h4>VALLEY <span className="green-text">EVENTS</span></h4>
                                  <h5 className="purple-text">FOR THE SPECIAL PERSONAL TOUCH</h5>
                                  <p>Valley Events is a wedding and event planning service company in Aberdeen with experience in both wedding planning and corporate event management. Based in Aberdeen we deliver exceptionally high quality and personal service to make your event seem like a dream come true.</p>
                                </td>
                              </tr>

                              <tr>
                                <td><img src={`https://fireworxscotland.co.uk/wp-content/uploads/2014/08/eventconsultants.jpg`} /></td>
                                <td>
                                  <h4>EVENT <span className="green-text">CONSULTANTS SCOTLAND</span></h4>
                                  <h5 className="purple-text">FOR ALL THE RIGHT REASONS</h5>
                                  <p>ECS works with its clients to create, manage and deliver every aspect of their events from the initial concept stage through to the gathering of feedback post event. We will help you to design your event by recommending both creative and workable solutions to ensure it runs smoothly, stands out from the crowd and is remembered by the right audience for the right reasons.</p>

                                </td>
                              </tr>
                            </table>

                          </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}



export default EventPage;
