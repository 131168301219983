import React from 'react';

import Breadcrumb from "../../../template/breadcrumb";
import { Helmet } from 'react-helmet'

const WeddingPyro = ({ }) => {

  return (
    <div>
    <Helmet>
        <title>Fireworx Scotland | Wedding Pyrotechnics</title>
        <meta name="description" content="From wedding cake cutting fountains to table centre fountains which make magnificent photos for you wedding day album." />
    </Helmet>
        <Breadcrumb title="Wedding Pyrotechnics"/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12">
                        <h2>WEDDING <span className="green-text">PYROTECHNICS</span></h2>

                          <div>
                            <h4>A TOUCH OF MAGIC FOR YOUR SPECIAL DAY</h4>

                            <p>Wedding day pyrotechnics have become a very popular addition to any large or small wedding function.</p>
                            <br/>

                            <p>From wedding cake cutting fountains to table centre fountains which make magnificent photos for you wedding day album.</p>
                            <br/>

                            <p>We also do a Variety of wedding décor for example: </p>
                            <br/>

                            <p>First dance confetti canon blasts, Up lighters for that fairy tale look along with fairy lights, Led Dance Floors, Fog Machines, bubble machines and much much more…</p>
                            <br/>

                          </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default WeddingPyro;
