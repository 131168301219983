import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';

import Breadcrumb from "../template/breadcrumb";

const Login = ({ login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const { email, password } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    login(email, password)
  };

  if (isAuthenticated) {
    return <Redirect to="/account/dashboard" />;
  }

  return (
    <div>
        <Breadcrumb title={'Login'}/>


        {/*Login section*/}
        <section className="login-page section-b-space">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <h3>Login</h3>
                        <div className="theme-card">
                            <form className="theme-form" onSubmit={e => onSubmit(e)}>
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="email"
                                      placeholder="Email"
                                      name="email"
                                      value={email}
                                      onChange={e => onChange(e)}
                                      required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="review">Password</label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      id="review"
                                      name="password"
                                      value={password}
                                      onChange={e => onChange(e)}
                                      placeholder="Enter your password"
                                      required
                                    />
                                </div>
                                <button type="submit" className="btn btn-solid">Login</button>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6 right-login">
                        <h3>New Customer</h3>
                        <div className="theme-card authentication-right">
                            <h6 className="title-font">Create A Account</h6>
                            <p>Sign up for a free account at our store. Registration is quick and easy. It
                                allows you to be able to order from our shop. To start shopping click
                                register.</p>
                            <Link to={`${process.env.PUBLIC_URL}/account/register`} className="btn btn-solid">Create an Account</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
  )
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login })(Login)
