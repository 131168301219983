import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {SlideUpDown} from "../../../services/script"
import LogoImage from "../headers/common/logo";
import { newsletterSignup } from '../../../actions/emails';

const Footer = ({ newsletterSignup }) => {

  useEffect(() => {
    let contentwidth = window.innerWidth;
    if ((contentwidth) < 750) {
        SlideUpDown('footer-title');
    } else {
        var elems = document.querySelectorAll(".footer-title");
        [].forEach.call(elems, function(elemt) {
            let el = elemt.nextElementSibling;
            el.style = "display: block";
        });
    }
  });

  let newsletterInit = {
    email: ''
  };

  const [ newsletterInput, setNewsletterInput ] = useState(newsletterInit);
  const { email } = newsletterInput;

  const onChange = e =>{
    setNewsletterInput({ ...newsletterInput, [e.target.name]: e.target.value });}

  return (
      <footer className="footer-light">
          <div className="light-layout">
              <div className="container">
                  <section className="small-section border-section border-top-0">
                      <div className="row">
                          <div className="col-lg-6">
                              <div className="subscribe">
                                  <div>
                                      <h4>DON'T MISS OUR DISPLAYS!</h4>
                                      <p>Sign up to out newsletter to find out about our upcoming displays</p>
                                  </div>
                              </div>
                          </div>
                          <div className="col-lg-6">
                              <form className="form-inline subscribe-form" onSubmit={e => {
                                e.preventDefault();
                                newsletterSignup(newsletterInput);
                                setNewsletterInput(newsletterInit);
                              }}>
                                  <div className="form-group mx-sm-3">
                                      <input type="text" className="form-control" name="email"
                                             placeholder="Enter your email" value={email} onChange={e => onChange(e)}/>
                                  </div>
                                  <button type="submit" className="btn btn-solid">subscribe</button>
                              </form>
                          </div>
                      </div>
                  </section>
              </div>
          </div>
          <section className="section-b-space light-layout">
              <div className="container">
                  <div className="row footer-theme partition-f">
                      <div className="col-lg-4 col-md-6">
                          <div className="footer-title footer-mobile-title">
                              <h4>about</h4>
                          </div>
                          <div className="footer-contant">
                              <div className="footer-logo">
                                  <img src={`${process.env.PUBLIC_URL}/assets/images/fireworx-footer.jpeg`} alt="" className="img-fluid"/>
                              </div>
                              <p>Fireworx Scotland Ltd is not only the leading Scottish Firework company but the UK number 1. We are confident in the claim and can support this by our success on an international stage and the awards we have achieved over the last 10-years.</p>
                              <div className="footer-social">
                                  <ul>
                                      <li>
                                          <a href="https://www.facebook.com/fireworxscotland/" ><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                      </li>
                                      <li>
                                          <a href=""><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                      </li>
                                      <li>
                                          <a href=""><i className="fa fa-instagram" aria-hidden="true"></i></a>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <div className="col offset-xl-1">
                          <div className="sub-title">
                              <div className="footer-title">
                                  <h4>Important Information</h4>
                              </div>
                              <div className="footer-contant">
                                  <ul>
                                  <li><Link to={`${process.env.PUBLIC_URL}/delivery-information`} >Delivery</Link></li>
                                  <li><Link to={`${process.env.PUBLIC_URL}/ordering-information`} >Ordering</Link></li>
                                  <li><Link to={`${process.env.PUBLIC_URL}/returns-policy`} >Returns</Link></li>
                                  <li><Link to={`${process.env.PUBLIC_URL}/gift-vouchers`} >Gift Vouchers</Link></li>
                                  <li><Link to={`${process.env.PUBLIC_URL}/about-us`} >About Us</Link></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <div className="col">
                          <div className="sub-title">
                              <div className="footer-title">
                                  <h4>The Small Print</h4>
                              </div>
                              <div className="footer-contant">
                                  <ul>
                                      <li><Link to={`${process.env.PUBLIC_URL}/privacy-policy`} >Privacy Policy</Link></li>
                                      <li><Link to={`${process.env.PUBLIC_URL}/cookies-policy`} >Cookie Policy</Link></li>
                                      <li><Link to={`${process.env.PUBLIC_URL}/terms-and-conditions`} >Terms and Conditions</Link></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <div className="col">
                          <div className="sub-title">
                              <div className="footer-title">
                                  <h4>store information</h4>
                              </div>
                              <div className="footer-contant">
                                  <ul className="contact-list">
                                      <li><i className="fa fa-map-marker"></i>Unit 7, Blackhall Industrial Estate,
                                      Inverurie, AB51 4FS
                                      </li>
                                      <li><i className="fa fa-phone"></i>Call Us: 01467 624872</li>
                                      <li><i className="fa fa-envelope-o"></i>Email Us: <a
                                          href="#">info@fireworxscotland.co.uk</a></li>
                                      <li>Open Mon - Fri: 9am - 5pm</li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <div className="sub-footer ">
              <div className="container">
                  <div className="row">
                      <div className="col-xl-6 col-md-6 col-sm-12">
                          <div className="footer-end">
                              <p><i className="fa fa-copyright" aria-hidden="true"></i> 2020 Fireworx Scotland Ltd
                                  Built By Billy :)</p>
                          </div>
                      </div>
                      <div className="col-xl-6 col-md-6 col-sm-12">
                          <div className="payment-card-bottom">
                              <ul>
                                  <li>
                                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon/visa.png`} alt="" />
                                  </li>
                                  <li>
                                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon/mastercard.png`} alt="" />
                                  </li>
                                  <li>
                                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon/paypal.png`} alt="" />
                                  </li>
                                  <li>
                                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon/american-express.png`} alt="" />
                                  </li>
                                  <li>
                                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon/discover.png`} alt="" />
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </footer>
  )
}

export default connect(null, { newsletterSignup })(Footer);
