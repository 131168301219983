import React, { useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { connect } from 'react-redux'

import { addToCart, addToWishlist, addToCompare } from "../actions/lists";
import { fetchFeaturedProducts } from '../actions/products';
import ProductItem from "./product-item";

const SpecialProducts = ({ addToCart, addToWishlist, addToCompare, fetchFeaturedProducts, products: { products, loading, symbol } }) => {

  useEffect(() => {
    fetchFeaturedProducts();
  }, [fetchFeaturedProducts]);

  return (
          <section className="section-b-space ratio_square">
              <div className="container-fluid">
                  <div className="title2">
                      <h4>new collection</h4>
                      <h2 className="title-inner2">trending products</h2>
                  </div>
                  <div className="row">
                      <div className="col">
                          <div className="theme-tab layout7-product">
                              <Tabs className="theme-tab">
                                  <TabList  className="tabs tab-title">
                                      <Tab>NEW ARRIVAL</Tab>
                                      <Tab>FEATURED </Tab>
                                      <Tab>SPECIAL</Tab>
                                  </TabList>

                                  <TabPanel>
                                      <div className="no-slider row">
                                          { products.slice(0, 8).map((product, index ) =>
                                              <ProductItem product={product} symbol={symbol}
                                                           onAddToCompareClicked={() => addToCompare(product)}
                                                           onAddToWishlistClicked={() => addToWishlist(product)}
                                                           onAddToCartClicked={() => addToCart(product, 1)} key={index} /> )
                                          }
                                      </div>
                                  </TabPanel>
                                  <TabPanel>
                                      <div className="no-slider row">
                                          { products.slice(8, 16).map((product, index ) =>
                                              <ProductItem product={product} symbol={symbol}
                                                           onAddToCompareClicked={() => addToCompare(product)}
                                                           onAddToWishlistClicked={() => addToWishlist(product)}
                                                           onAddToCartClicked={() => addToCart(product, 1)} key={index} /> )
                                          }
                                      </div>
                                  </TabPanel>
                                  <TabPanel>
                                      <div className=" no-slider row">
                                          { products.slice(16, products.length).map((product, index ) =>
                                              <ProductItem product={product} symbol={symbol}
                                                           onAddToCompareClicked={() => addToCompare(product)}
                                                           onAddToWishlistClicked={() => addToWishlist(product)}
                                                           onAddToCartClicked={() => addToCart(product, 1)} key={index} /> )
                                          }
                                      </div>
                                  </TabPanel>
                              </Tabs>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
  )
}

const mapStateToProps = (state) => {
    return {
      products: state.product,
      filters: state.filters
    }

}

// const mapStateToProps = (state, ownProps) => ({
//     bestSeller: "",
//     newProducts: "",
//     featuredProducts: "",
//     symbol: "£"
// })

export default connect(mapStateToProps, { addToCart, addToWishlist, addToCompare, fetchFeaturedProducts }) (SpecialProducts);
