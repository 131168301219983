import React from 'react';
import Breadcrumb from "../../../template/breadcrumb";
import { Helmet } from 'react-helmet'

const FestivalPage = ({ }) => {

  return (
    <div>
    <Helmet>
        <title>Fireworx Scotland | Festival Fireworks</title>
        <meta name="description" content="Fireworx Scotland Ltd have provided firework finale’s for the last 10 years for various festivals." />
    </Helmet>
        <Breadcrumb title="Festival Fireworks"/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12">
                        <h2>FESTIVAL <span className="green-text">FIREWORKS</span></h2>

                          <div>
                            <h4>A DAY TO REMEMBER</h4>

                            <p>Fireworx Scotland Ltd have provided firework finale’s for the last 10 years for various festivals.</p>
                            <br/>

                            <p className="green-text">Including:</p>
                            <br/>

                            <p>Rockness 2005 – 2014</p>
                            <br/>

                            <p>Wizard Festival 2007 – 2012 (Returning 2014)</p>
                            <br/>

                            <p>Belladrum Festival  2003 – 2014 (The Tartan Heart Festival)</p>
                            <br/>

                            <p>The Wickerman Festival 2005 – 2014</p>
                            <br/>

                            <p>We have also provided various special effects for all of the above festivals & other events, see our EventFX website.</p>
                            <br/>

                          </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}


export default FestivalPage;
