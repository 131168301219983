import React from 'react';

import { Link } from 'react-router-dom';

import Breadcrumb from "../../../template/breadcrumb";
import { Helmet } from 'react-helmet'

const ServicesPage = ({ }) => {

  return (
    <div>
        <Breadcrumb title="Wedding Related Services"/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12">
                        <h2>WEDDING <span className="green-text">RELATED SERVICES</span></h2>

                          <div>
                            <h4>EVERYTHING YOU NEED</h4>

                            <table className="table cart-table table-responsive-xs">
                              <tr>
                                <td><img src={`https://fireworxscotland.co.uk/wp-content/uploads/2014/08/Enchanted-Boutique.jpg`} /></td>
                                <td>
                                  <h4>ENCHANTED <span className="green-text">BOUTIQUE</span></h4>
                                  <h5 className="purple-text">FASHION DESIGNER</h5>
                                  <p>Specialist in Bridal Alterations, evening alterations</p>
                                  <p>Designer and Dressmaker</p>
                                  <p>New Bridal Collection</p>
                                  <p>Dressing on the Day Service</p>
                                  <p>Unique Wedding Flavours</p>
                                </td>
                              </tr>

                              <tr>
                                <td><img src={`https://fireworxscotland.co.uk/wp-content/uploads/2014/08/isla-duncan.jpg`} /></td>
                                <td>
                                  <h4>ISLA <span className="green-text">DUNCAN</span></h4>
                                  <h5 className="purple-text">FLORIST</h5>
                                  <p></p>
                                </td>
                              </tr>

                            </table>

                          </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}



export default ServicesPage;
