import {
    FILTER_SORT,
    CLEAR_STATE,
    CREATE_ORDER,
    FINALIZE_ORDER,
    MY_ORDERS
  } from "../actions/types";

const initialState = {
  order: null,
  orders: [],
  orderChanged: false
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch(type){
    case FILTER_SORT:
      return {
        ...state,
        sortBy: action.sort_by
      };
      case CREATE_ORDER:
        return {
          ...state,
          order: payload,
          orderChanged: true
        }
      case FINALIZE_ORDER:
        return {
          ...state,
          order: payload,
          orderChanged: true
        }
      case MY_ORDERS:
        return {
          ...state,
          orders: payload
        }
      case CLEAR_STATE:
        return initialState;
    default:
      return state;
  }
}
