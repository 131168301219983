import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import './template/index.scss';
import Slider from 'react-slick';

// Import custom components

import SpecialProducts from "./SpecialProducts";


class Home extends Component {

    render(){

        return (
              <Fragment>
                <section className="padding-bottom-cls container">
                    <div className="container-fluid collection-banner">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/banner.png`} className="img-fluid blur-up lazyload bg-img" />
                    </div>
                </section>

                <div className="layout-8-bg container">
                    {/*About Section*/}
                    <section className="banner-goggles ratio2_3">
                        <div className="container-fluid">
                            <div className="row partition3">
                                <div className="col-md-4">
                                    <Link to={`${process.env.PUBLIC_URL}/products/category/display-packs`}>
                                        <div className="collection-banner">
                                            <div className="img-part">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/fireworx/promo-one.jpg`}
                                                     className="img-fluid blur-up lazyload bg-img" alt="" />
                                            </div>
                                            <div className="contain-banner banner-3">
                                                <div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-md-4">
                                    <Link to={`${process.env.PUBLIC_URL}/products/category/display-packs`}>
                                        <div className="collection-banner">
                                            <div className="img-part">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/fireworx/promo-two.jpg`}
                                                     className="img-fluid blur-up lazyload bg-img" alt="" />
                                            </div>
                                            <div className="contain-banner banner-3">
                                                <div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-md-4">
                                    <Link to={`${process.env.PUBLIC_URL}/products/category/display-packs`}>
                                        <div className="collection-banner">
                                            <div className="img-part">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/fireworx/promo-three.jpg`}
                                                     className="img-fluid blur-up lazyload bg-img" alt="" />
                                            </div>
                                            <div className="contain-banner banner-3">
                                                <div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*About Section End*/}

                    {/*Product slider*/}
                    <SpecialProducts type={'electronics'} />
                    {/*Product slider End*/}
                </div>

            </Fragment>
        )
    }
}


export default Home;
