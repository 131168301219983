import React from 'react';

import Breadcrumb from "../../../template/breadcrumb";
import { Helmet } from 'react-helmet'

const CelebrationPage = ({ }) => {

  return (
    <div>
    <Helmet>
        <title>Fireworx Scotland | Celebration of Life Fireworx</title>
        <meta name="description" content="We are able to distribute ashes in a beautiful way through the use of fireworks. Let your loved one drift down under a canopy of brightness in the night sky to settle in his/her favourite place." />
    </Helmet>
        <Breadcrumb title="Celebration of life"/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12">
                        <h2>CELEBRATION OF LIFE <span className="green-text">FIREWORKS</span></h2>

                          <div>
                            <h4>CELEBRATE THE LIFE OF A LOVED ONE</h4>
                            <p>At Fireworx Scotland we have had a lot of requests about fireworks to celebrate the life of loved ones or to scatter their ashes.</p>
                            <p>We arrange special fireworks displays which include a number of unique fireworks designed or modified to incorporate cremation ashes, allowing for a spectacular memorial event and happier farewell.</p>
                            <br/>
                            <p>Celebration of Life firework displays and funeral fireworks are becoming an ever more apparent feature in our business.</p>
                            <br/>
                            <p>We are able to distribute ashes in a beautiful way through the use of fireworks. Let your loved one drift down under a canopy of brightness in the night sky to settle in his/her favourite place.</p>
                            <br/>
                            <p>Due to the nature of these displays we often use far quieter fireworks with more glittering and falling sequences to match the mood of the display. However your choices will dictate the display as always so please let us know what it is you want</p>
                          </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}


export default CelebrationPage;
