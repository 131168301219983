import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class NavBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navClose: { right: '0px' }
        }
    }

    componentWillMount() {
        if (window.innerWidth < 750) {
            this.setState({ navClose: { right: '-410px' } })
        }
        if (window.innerWidth < 1199) {
            this.setState({ navClose: { right: '-300px' } })
        }
    }

    openNav() {
        console.log('open')
        this.setState({ navClose: { right: '0px' } })
    }
    closeNav() {
        this.setState({ navClose: { right: '-410px' } })
    }

    onMouseEnterHandler() {
        if (window.innerWidth > 1199) {
            document.querySelector("#main-menu").classList.add("hover-unset");
        }
    }

    handleSubmenu = (event) => {
        if (event.target.classList.contains('sub-arrow'))
            return;

        if(event.target.nextElementSibling.classList.contains('opensubmenu'))
            event.target.nextElementSibling.classList.remove('opensubmenu')
        else{
            document.querySelectorAll('.nav-submenu').forEach(function (value) {
                value.classList.remove('opensubmenu');
            });
            document.querySelector('.mega-menu-container').classList.remove('opensubmenu')
            event.target.nextElementSibling.classList.add('opensubmenu')
        }
    }

    handleMegaSubmenu = (event) => {
        if (event.target.classList.contains('sub-arrow'))
            return;

        if(event.target.parentNode.nextElementSibling.classList.contains('opensubmegamenu'))
            event.target.parentNode.nextElementSibling.classList.remove('opensubmegamenu')
        else{
            document.querySelectorAll('.menu-content').forEach(function (value) {
                value.classList.remove('opensubmegamenu');
            });
            event.target.parentNode.nextElementSibling.classList.add('opensubmegamenu')
        }
    }

    render() {
        return (
            <div>
                <div className="main-navbar">
                    <div id="mainnav" >
                        <div className="toggle-nav" onClick={this.openNav.bind(this)} >
                            <i className="fa fa-bars sidebar-bar"></i>
                        </div>
                        <ul className="nav-menu" style={this.state.navClose}>
                            <li className="back-btn" onClick={this.closeNav.bind(this)} >
                                <div className="mobile-back text-right">
                                    <span >Back</span>
                                    <i className="fa fa-angle-right pl-2" aria-hidden="true"></i>
                                </div>
                            </li>

                            <li >
                                <Link to={`${process.env.PUBLIC_URL}`} className="nav-link" onClick={(e) => this.handleSubmenu(e)}>
                                    {'home'}
                                </Link>
                            </li>
                            <li >
                                <Link to="#" className="nav-link" onClick={(e) => this.handleSubmenu(e)}>
                                    {'products'}
                                    <span className="sub-arrow"></span>
                                </Link>
                                <ul className="nav-submenu">
                                    <li><Link to={`${process.env.PUBLIC_URL}/products/category/low-noise-fireworks`} >{'low noise fireworks'}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/products/category/cakes-and-barrages`} >{'cakes & barrages'}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/products/category/rockets`} >{'rockets'}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/products/category/display-packs`} >{'display packs'}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/products/category/selection-boxes`} >{'selection boxes'}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/products/category/fountains-and-mines`} >{'fountains & mines'}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/products/category/miscellaneous`} >{'miscellaneous'}</Link></li>
                                </ul>
                            </li>
                            <li className="mega-menu">
                                <Link to="#" className="dropdown" onClick={(e) => this.handleSubmenu(e)}>
                                    {'displays'}
                                    <span className="sub-arrow"></span>
                                </Link>
                                <div className="mega-menu-container" >
                                    <div className="container">
                                        <div className="row">
                                            <div className="col mega-box">
                                                <div className="link-section">
                                                    <div className="menu-title" >
                                                        <h5 onClick={(e) => this.handleMegaSubmenu(e)}>
                                                            {'fireworks'}
                                                            <span className="sub-arrow"></span>
                                                        </h5>
                                                    </div>
                                                    <div className="menu-content">
                                                        <ul>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/about-us`} >{'about us'}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/displays/fireworks/celebration-life-fireworks`} >{'celebration of life fireworks'}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/displays/fireworks/corporate-fireworks`} >{'corporate fireworks'}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/displays/fireworks/festival-fireworks`} >{'festival fireworks'}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/displays/fireworks/wedding-fireworks`} >{'wedding fireworks'}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/displays/fireworks/musical-fireworks`} >{'musical fireworks'}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/displays/fireworks/public-fireworks`} >{'public fireworks'}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/displays/fireworks/quiet-fireworks`} >{'quiet fireworks'}</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col mega-box">
                                                <div className="link-section">
                                                    <div className="menu-title" >
                                                        <h5 onClick={(e) => this.handleMegaSubmenu(e)}>
                                                            {'pyrotechnics'}
                                                            <span className="sub-arrow"></span>
                                                        </h5>
                                                    </div>
                                                    <div className="menu-content">
                                                        <ul>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/displays/pyrotechnics/anniversary-pyrotechnics`} >{'anniversary pyrotechnics'}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/displays/pyrotechnics/birthday-pyrotechnics`} >{'birthday pyrotechnics'}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/displays/pyrotechnics/wedding-pyrotechnics`} >{'wedding pyrotechnics'}</Link></li>
                                                            {/*<li><Link to={`${process.env.PUBLIC_URL}/features/element-ratio`} >{'image_size_ratio'}</Link></li>*/}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col mega-box">
                                                <div className="link-section">
                                                    <div className="menu-title" >
                                                        <h5 onClick={(e) => this.handleMegaSubmenu(e)}>
                                                            {'information'}
                                                            <span className="sub-arrow"></span>
                                                        </h5>
                                                    </div>
                                                    <div className="menu-content" >
                                                        <ul>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/displays/information/event-management`} >{'event management'}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/displays/information/recommended-photographers`} >{'photographers'}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/displays/information/wedding-cars`} >{'wedding cars'}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/displays/information/wedding-planners`} >{'wedding planners'}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/displays/information/wedding-related-services`} >{'wedding related services'}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/displays/information/wedding-venues`} >{'wedding venues'}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/displays/information/fireworks-safety`} >{'firework safety information'}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/displays/information/testimonials`} >{'testimonials'}</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li >
                                <Link to="#" className="nav-link" onClick={(e) => this.handleSubmenu(e)}>
                                    gallery
                                </Link>

                            </li>

                            <li>
                                <Link to={`${process.env.PUBLIC_URL}/contact-us`} className="nav-link">
                                    {'contact'}
                                </Link>

                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}


export default NavBar;
