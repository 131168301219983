import React from 'react';

import Breadcrumb from "../../../template/breadcrumb";
import { Helmet } from 'react-helmet'

const MusicalPage = ({ }) => {

  return (
    <div>

    <Helmet>
        <title>Fireworx Scotland | Musical Fireworks</title>
        <meta name="description" content="Music to fireworks adds extra emotional resonance to an occasion. It may be that you’d like a special programme of music with fireworks to mark a significant event or anniversary. We’ll be only too delighted to choreograph your display for you." />
    </Helmet>
        <Breadcrumb title="Musical Fireworks"/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12">
                        <h2>MUSICAL <span className="green-text">FIREWORKS</span></h2>

                          <div>
                            <h4>COREOGRAPHING FIREWORKS TO MUSIC</h4>

                            <p>Music to fireworks adds extra emotional resonance to an occasion. It may be that you’d like a special programme of music with fireworks to mark a significant event or anniversary. We’ll be only too delighted to choreograph your display for you.</p>
                            <br/>

                            <p>Digital firing systems accurate to a split second mean we can choreograph musical firework displays with an exactness which can respond to the slightest nuance in a piece of music. It doesn’t matter whether it’s pre-recorded or performed in real time – we’re just as at home with Mozart as we are with Metallica.</p>
                            <br/>

                            <p>Fireworx Scotland Ltd work alongside the client to achieve the result they require & we design our shows with Finale Software which we are able to supply the client with a demo simulation video.</p>
                            <br/>

                            <p>Fireworx Scotland Ltd have competed in various Musical Firework Championships over the past few years… See below.</p>
                            <br/>

                          </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}


export default MusicalPage;
