import {
    ADD_TO_WISHLIST,
    REMOVE_FROM_WISHLIST,
    FETCH_WISHLIST
   } from "../actions/types";

const initialState = {
  list: []
};

const wishlistReducer = (state = initialState, action) => {

  const { type, payload } = action;

  switch(type) {
    case ADD_TO_WISHLIST:
    const productId = action.product._id
    if (state.list.findIndex(product => product._id === productId) !== -1) {
        const list = state.list.reduce((cartAcc, product) => {
            if (product._id === productId) {
                cartAcc.push({ ...product })
            } else {
                cartAcc.push(product)
            }

            return cartAcc
        }, [])

        return { ...state, list }
    }

    return { ...state, list: [...state.list, action.product] }

  case REMOVE_FROM_WISHLIST:
  return {
      ...state,
      list: state.list.filter(item => item._id !== payload)
  }

  case FETCH_WISHLIST:
    console.log(payload)
    return{
      ...state,
      list: payload
    }

  default:
    return state;
  }

}

export default wishlistReducer;
