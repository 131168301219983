import React from 'react';

import { Link } from 'react-router-dom';

import Breadcrumb from "../../../template/breadcrumb";
import { Helmet } from 'react-helmet'

const PlannersPage = ({ }) => {

  return (
    <div>
        <Breadcrumb title="Wedding Planners"/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12">
                        <h2>WEDDING <span className="green-text">PLANNERS</span></h2>

                          <div>
                            <h4>PEOPLE WHO DEMAND FIRST CLASS SERVICE</h4>

                            <table className="table cart-table table-responsive-xs">
                              <tr>
                                <td><img src={`https://fireworxscotland.co.uk/wp-content/uploads/2014/08/timeless-white.jpg`} /></td>
                                <td>
                                  <h4>TIMELESS <span className="green-text">WHITE</span></h4>
                                  <h5 className="purple-text">PERFECTIONS A MUST</h5>
                                  <p>Timeless White are Scotland’s prestige Wedding Planners, offering a variety of Wedding and Celebration Planning services, starting at just £100. We take pride in offering a personal but meticulous service that will ensure your Wedding is as stress-free and wonderful as you have dreamt.

We also supply Wedding decoration and hire services, including drapes, dancefloors, floristry and Wedding Centrepieces to make your Wedding every bit as beautiful as you have envisioned.</p>
                                </td>
                              </tr>

                              <tr>
                                <td><img src={`https://fireworxscotland.co.uk/wp-content/uploads/2014/08/northeast-events.png`} /></td>
                                <td>
                                  <h4>NORTH EAST <span className="green-text">EVENTS</span></h4>
                                  <h5 className="purple-text">FRESH IDEAS</h5>
                                  <p>North East Events in Aberdeen specialise in Weddings from the formal and traditional through to the wacky and unusual. Having been wedding planners for 16 years we will be with you through all the different steps to make sure your day goes smoothly.
Through the 1000’s of hours experience we will ensure you will have exactly the day you want and not what other people think you should have. North East Events will work with you to determine your overall needs and budget, and develop an event specifically catered to your needs.</p>
                                </td>
                              </tr>

                            </table>

                          </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}



export default PlannersPage;
