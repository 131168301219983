import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Slider from 'react-slick';

import { Link } from 'react-router-dom';


import Breadcrumb from "../../template/breadcrumb";
import {Slider2, Team4} from "../../../services/script";

const AboutPage = ({ }) => {

  return (
    <div>
        <Breadcrumb title="about us"/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12">
                        <h2><span className="green-text">ABOUT</span> FIREWORX SCOTLAND</h2>
                        <p><strong><span className="green-text">NOW</span> <span className="purple-text">IMAGINE</span></strong></p>
                          <div>
                            <p><strong><span className="green-text">Fireworx</span> <span className="purple-text">Scotland</span> Ltd</strong> is not only the leading Scottish Firework company but the UK number 1. We are confident in the claim and can support this by our success on an international stage and the awards we have achieved over the last 10-years.</p>
                            <p>If you want to say that something special at your event do not take the risk of disappointment. We provide a personal service, planning every detail of your display and ensuring you achieve the impact required. We are also happy to work to a budget maximising value for money. As a family business we pride ourselves on quality and customer service.</p>
                            <p>Customers hire Fireworx Scotland year after year because they appreciate our value for money. If we are very occasionally beaten on price we will never be beaten on our value for money, service, safety and professionalism!</p>
                            <p><strong><span className="green-text">We deliver</span> <span className="purple-text">"more than you can imagine!"</span></strong></p><br/>
                          </div>
                        <h2><span className="green-text">BUYING</span> POWER</h2>
                        <p><strong><span className="green-text">MORE BANG FOR</span> <span className="purple-text">YOUR BUCKS</span></strong></p>
                          <div>
                            <p>This means you get more for your display. We can buy in bulk but also at the lowest wholesale rates and as a result we can build a package for your display ensuring you get more for your money. Retail fireworks, unit price, are sold at a similar price to our managed displays and with professional fireworks the impact and display is a factor of 10 better.</p>
                            <p>Any budget over £750.00 can be accommodated with a managed display.</p>
                          </div>
                          <br/>
                        <h2><span className="green-text">FAMILY</span> BUSINESS</h2>
                        <p><strong><span className="green-text">PERSONAL</span> <span className="purple-text">SERVICE</span></strong></p>
                          <div>
                            <p>This is important and a statement we are proud of. Our staff are motivated by the industry they work in but we will make sure our values are carried across as each one meets and greets the customer, during set-up, delivers the display and represents the company at events. We understand that you expect the highest level of service and dealing with people who provide a personal service.</p>
                          </div>
                          <br/>
                        <h2><span className="green-text">CUSTOMER</span> SERVICE</h2>
                        <p><strong><span className="green-text">FRIENDLY</span> <span className="purple-text">SERVICE</span></strong></p>
                          <div>
                            <p>Our business is dependent on our last performance. If we were to get it wrong it means people remember that problem display not years of successful delivery. Because of this we plan meticulously, check and double check all firings, routines and event schedule to ensure on the day nothing can go wrong. Others do not and their results speak for themselves. If you want the number one for customer service you only can consider Fireworx Scotland!</p>
                          </div>
                          <br/>
                        <h2><span className="green-text">OUR</span> COVERAGE</h2>
                        <p><strong><span className="green-text">SCOTLAND &</span> <span className="purple-text">NORTHERN ENGLAND</span></strong></p>
                          <div>
                            <p>We are not just Aberdeenshire based we have facilities in Central Scotland and the Highlands which allows us to provide a Scotland wide coverage and also include the North of England. We make sure your event is managed by our local representative.</p>
                          </div>
                          <br/>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

AboutPage.propTypes = {
  fetchPage: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired
};


export default connect(null, {  })(AboutPage)
