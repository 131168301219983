import {
  FETCH_PAGE,
  PAGE_ERROR
} from './types';
import axios from 'axios';

// Get page
export const fetchPage = slug => async dispatch => {

  try {

    const response = await axios.get(`/api/pages/${slug}`);

    dispatch({
      type: FETCH_PAGE,
      payload: response.data
    });

  } catch (e) {
    dispatch({
      type: PAGE_ERROR
    })
  }
}
