import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Slider from 'react-slick';

import { Link } from 'react-router-dom';

import Breadcrumb from "../../template/breadcrumb";
import {Slider2, Team4} from "../../../services/script";

const ReturnsPage = ({ }) => {

  return (
    <div>
        <Breadcrumb title="Returns"/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12">
                        <h2><span className="green-text">RETURNING</span> A PRODUCT</h2>
                        <div>
                          <h4>We are so confident in our products, and are sure you will be happpy too. So, with every purchase that you make from us, we offer a 100% satisfaction guarantee</h4>
                            <p>Should you wish to return a purchase within 14 days, please <Link to={`${process.env.PUBLIC_URL}/contact-us`} className="green-text">contact us first</Link></p>
                            <p>We will be happy to refund or exchange any product, for any reason, provided it is in resaleable condition. You are obliged to take reasonable care of the goods whilst in your charge. On satisfactory return, we will refund your payment through the same payment method used to purchase the products.</p>
                            <p>We will refund your original delivery charge plus the return delivery charge when a product is faulty, damaged or incorrectly shipped. However if the return is an unwanted product, we will not be liable for the direct cost of return.</p>
                              <br/>
                              <h5>Cancellation of Custom Made Products</h5>
                            <p>In respect of made-to-order, customised or custom printed products our usual refund policy does not apply and products cannot be returned or exchanged unless faulty. After seven working days, any deposits for special orders or custom printed orders will not be refunded on cancellation. Your deposit after this period confirms the contract.</p>
                            <p>None of the above conditions affect your statutory rights when goods are faulty, or not as described.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

ReturnsPage.propTypes = {
  fetchPage: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired
};


export default connect(null, {  })(ReturnsPage)
