import {
  PRODUCTS_BY_CATEGORY,
  FETCH_PRODUCT,
  RELATED_PRODUCTS,
  FETCH_FEATURED,
  SEARCH_PRODUCTS,
  ERROR
} from './types';
import axios from 'axios';

export const productsByCategory = slug => async dispatch => {
  try {
    const response = await axios.get(`/api/products/category/${slug}`);

    dispatch({
      type: PRODUCTS_BY_CATEGORY,
      payload: response.data
    });
  } catch (e) {
    dispatch({
      type: ERROR
    })
  }
}

export const fetchProduct = slug => async dispatch => {
  try {
    const response = await axios.get(`/api/products/product/${slug}`);

    dispatch({
      type: FETCH_PRODUCT,
      payload: response.data
    });
  } catch (e) {
    dispatch({
      type: ERROR
    })
  }
}

export const fetchFeaturedProducts = () => async dispatch => {
  try {
    const response = await axios.get('/api/products/?featured=true&limit=24');

    dispatch({
      type: FETCH_FEATURED,
      payload: response.data
    });
  } catch(e){
    dispatch({
      type: ERROR
    })
  }
}

export const searchProducts = searchTerm => async dispatch => {
  try{
    const response = await axios.get(`/api/products/search/${searchTerm}`);

    dispatch({
      type: SEARCH_PRODUCTS,
      payload: response.data
    });
  } catch(e){
    dispatch({
      type: ERROR
    });
  }
}
