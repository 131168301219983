import {
    FILTER_SORT
  } from "../actions/types";

const initialState = {
  sortBy: "",
  brand: ["nike", "caprese", "lifestyle"],
  value: { min: 250, max: 950 }
};

export default function(state = initialState, action) {
  const { type } = action;

  switch(type){
    case FILTER_SORT:
      return {
        ...state,
        sortBy: action.sort_by
      };
    default:
      return state;
  }
}
