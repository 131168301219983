import React, { useState } from 'react';
import Breadcrumb from "../template/breadcrumb";
import { connect } from 'react-redux';
import { submitContactForm } from '../../actions/emails';

const Contact = ({ submitContactForm }) => {

  let formDetails = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    message: ''
  };

  const [ formData, setFormData ] = useState(formDetails);

  const { firstName, lastName, phone, email, message } = formData;

  const onChange = e =>{
    setFormData({ ...formData, [e.target.name]: e.target.value });}

  return (
      <div>
          <Breadcrumb title={'Contact Us'}/>


          {/*Forget Password section*/}
          <section className=" contact-page section-b-space">
              <div className="container">
                  <div className="row section-b-space">
                      <div className="col-lg-7 map">
                          <iframe
                              src="https://maps.google.com/maps?q=fireworx%20scotland%20ltd,%207%20blackhall%20industrial&amp;t=&amp;z=10&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                              allowFullScreen></iframe>
                      </div>
                      <div className="col-lg-5">
                          <div className="contact-right">
                              <ul>
                                  <li>
                                      <div className="contact-icon">
                                          <i className="fa fa-phone" aria-hidden="true"></i>
                                              <h6 className="theme-color">Contact Us</h6>
                                      </div>
                                      <div className="media-body">
                                          <p>01467 624872</p>
                                          <p>07894 354530</p>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="contact-icon">
                                          <i className="fa fa-map-marker" aria-hidden="true"></i>
                                          <h6>Address</h6>
                                      </div>
                                      <div className="media-body">
                                          <p>Unit 7, Blackhall Ind. Estate, Inverurie</p>
                                          <p>AB51 4FS</p>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="contact-icon">
                                          <i className="fa fa-envelope" aria-hidden="true"></i>
                                              <h6>Email</h6>
                                      </div>
                                      <div className="media-body">
                                          <p>info@fireworxscotland.co.uk</p>
                                      </div>
                                  </li>

                              </ul>
                          </div>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-sm-12">
                          <form className="theme-form" onSubmit={e => {
                            e.preventDefault();
                            submitContactForm(formData);
                            setFormData(formDetails)
                          }}>
                              <div className="form-row">
                                  <div className="col-md-6">
                                      <label htmlFor="name">First Name</label>
                                      <input type="text" className="form-control" name="firstName"
                                             placeholder="Enter Your name" required="" value={firstName} onChange={e => onChange(e)} />
                                  </div>
                                  <div className="col-md-6">
                                      <label htmlFor="email">Last Name</label>
                                      <input type="text" className="form-control" name="lastName"
                                             placeholder="Email" required="" value={lastName} onChange={e => onChange(e)} />
                                  </div>
                                  <div className="col-md-6">
                                      <label htmlFor="review">Phone number</label>
                                      <input type="text" className="form-control" name="phone"
                                             placeholder="Enter your number" required="" value={phone} onChange={e => onChange(e)} />
                                  </div>
                                  <div className="col-md-6">
                                      <label htmlFor="email">Email</label>
                                      <input type="text" className="form-control" name="email" placeholder="Email"
                                             required="" value={email} onChange={e => onChange(e)} />
                                  </div>
                                  <div className="col-md-12">
                                      <label htmlFor="review">Write Your Message</label>
                                      <textarea className="form-control" placeholder="Write Your Message"
                                                name="message" rows="6" value={message} onChange={e => onChange(e)} ></textarea>
                                  </div>
                                  <div className="col-md-12">
                                      <button className="btn btn-solid" type="submit">Send Your Message</button>
                                  </div>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </section>

      </div>
  )
};

export default connect(null, { submitContactForm })(Contact)
