import React, { useState } from 'react';
import Breadcrumb from '../template/breadcrumb';
import { Redirect } from 'react-router-dom'

const Search = ({ history }) => {

  let searchForm = {
    searchTerm: ''
  };


  const [ searchInput, setSearchInput ] = useState(searchForm);
  const { searchTerm } = searchInput;

  const onChange = e =>{
    setSearchInput({ ...searchInput, [e.target.name]: e.target.value });}

  const search = (e, searchTerm) => {
    history.push(`/products/search/${searchTerm}`)
  }

  return (
            <div>
                <Breadcrumb title={'Search'}/>


                {/*Search section*/}
                <section className="authentication-page section-b-space">
                    <div className="container">
                        <section className="search-block">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 offset-lg-3">
                                        <form className="form-header" onSubmit={e => search(e, searchTerm)}>
                                            <div className="input-group">
                                                <input type="text" className="form-control"
                                                       placeholder="Search Products......"
                                                       name="searchTerm"
                                                       value={searchTerm}
                                                       onChange={e => onChange(e)}/>
                                                    <div className="input-group-append">
                                                        <button className="btn btn-solid"><i
                                                            className="fa fa-search"></i>Search
                                                        </button>
                                                    </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>

            </div>
        )
}

export default Search;
