import React from 'react';

import Breadcrumb from "../../../template/breadcrumb";
import { Helmet } from 'react-helmet'

const AnniversaryPyro = ({ }) => {

  return (
    <div>
    <Helmet>
        <title>Fireworx Scotland | Anniversary Pyrotechnics</title>
        <meta name="description" content="If you’re planning an event and want to find out more about close proximity indoor fireworks or stage pyrotechnics suitable for use in stadiums, arenas, town centres, on rooftops and indoors then contact us and we’ll happily advise you as to what can be achieved, how it will work, and how much it will cost." />
    </Helmet>
        <Breadcrumb title="Anniversary Pyrotechnics"/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12">
                        <h2>ANNIVERSARY <span className="green-text">PYROTECHNICS</span></h2>

                          <div>
                            <h4>ADD THAT SOMETHING EXTRA</h4>

                            <p>Anniversary Pyrotechnics are becoming more and more popular Fireworx Scotland Ltd have worked with various clients to achieve many spectacular effects on their special day.</p>
                            <br/>

                            <p>Fireworx Scotland Ltd have been able to source UK produced Pyrotechnics from Wells Fireworks & Le Maitre.</p>
                            <br/>

                            <p>Cat 1 Stage Pyrotechnics add extra impact and panache to any events where Cat 4  fireworks are simply not an option.</p>
                            <br/>

                            <p>If you’re planning an event and want to find out more about close proximity indoor fireworks or stage pyrotechnics suitable for use in stadiums, arenas, town centres, on rooftops and indoors then contact us and we’ll happily advise you as to what can be achieved, how it will work, and how much it will cost.</p>
                            <br/>


                          </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}


export default AnniversaryPyro;
