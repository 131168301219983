import React from 'react';

import { Link } from 'react-router-dom';

import Breadcrumb from "../../../template/breadcrumb";
import { Helmet } from 'react-helmet'

const VenuesPage = ({ }) => {

  return (
    <div>
        <Breadcrumb title="Wedding Venues"/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12">
                        <h2>WEDDING <span className="green-text">VENUES</span></h2>

                          <div>
                            <h4>A LITTLE SOMETHING SPECIAL</h4>

                          </div>
                          <table className="table cart-table table-responsive-xs">
                            <tr>

                              <td>
                                <h4>BALLATHIE <span className="green-text">HOUSE HOTEL</span></h4>
                                <p>One of the top wedding venues in Scotland, Ballathie was voted Best Country House Wedding Venue at the 2011 Scottish Hotel Awards. For a very special day.</p>
                              </td>
                            </tr>

                            <tr>

                              <td>
                                <h4>FASQUE <span className="green-text">HOUSE</span></h4>
                                <p>Fasque House is a naturally exquisite country estate with a beautiful baronial mansion at its heart, all for your exclusive use in the Scottish Highlands.</p>
                              </td>
                            </tr>

                            <tr>

                              <td>
                                <h4>DRUMTOCHTY <span className="green-text">CASTLE</span></h4>
                                <p>We invite you to enjoy the castle exclusively as your own, or with family & friends to celebrate that special moment in your life in a relaxed & historic atmosphere.</p>
                              </td>
                            </tr>

                            <tr>

                              <td>
                                <h4>GLEN <span className="green-text">TANAR</span></h4>
                                <p>A taste of tradition in a modern world.Take a step back in time into the beautiful world of Glen Tanar where this historical home welcomes you with outstanding hospitality.</p>
                              </td>
                            </tr>

                            <tr>

                              <td>
                                <h4>PITTODRIE <span className="green-text">HOUSE</span></h4>
                                <p>A perfect venue for celebrations, events and conferences – boasting superb guest accommodation, stylish meeting and function rooms, plus breathtaking gardens and surrounding woodland.</p>
                              </td>
                            </tr>

                            <tr>

                              <td>
                                <h4>LOGIE COUNTRY <span className="green-text">HOUSE</span></h4>
                                <p>A perfect venue for celebrations, events and conferences – boasting superb guest accommodation, stylish meeting and function rooms, plus breathtaking gardens and surrounding woodland.</p>
                              </td>
                            </tr>

                            <tr>

                              <td>
                                <h4>ARDOE <span className="green-text">HOUSE</span></h4>
                                <p>Mercure Aberdeen Ardoe House Hotel and Spa is a 19th century mansion house set in 30 acres of tranquil countryside. Luxurious treatment rooms as well as an indoor heated pool and fitness room.</p>
                              </td>
                            </tr>

                            <tr>

                              <td>
                                <h4>RAEMOIR <span className="green-text">HOUSE</span></h4>
                                <p>The 3 AA star Raemoir is one of the finest Country House Hotels in Aberdeenshire, famed for its warmth and hospitality since it opened in 1943. We are sure you will be pleasantly surprised.</p>
                              </td>
                            </tr>

                            <tr>

                              <td>
                                <h4>LOCH TORRIDON <span className="green-text">CENTRE</span></h4>
                                <p>Make your special day one to remember
You will receive a warm Highland welcome at our beautifully designed venue situated amongst breathtaking scenery with stunning views on the shores of Loch Torridon.</p>
                              </td>
                            </tr>

                            <tr>

                              <td>
                                <h4>GLENMORANGIE <span className="green-text">HOUSE</span></h4>
                                <p>At Glenmorangie House we understand that your wedding is one of the most special and memorable days of your life, a personal occasion which should run as smooth as butter but still have all the excitement of a popping champagne cork.</p>
                              </td>
                            </tr>

                            <tr>

                              <td>
                                <h4>MELDRUM <span className="green-text">HOUSE</span></h4>
                                <p>Set in 240 acres of parkland, with a private lake complete with swans, this ancient baronial-style house is a truly romantic setting and a stunning backdrop for your wedding reception and photographs.Our very best personal attention to every detail in helping create your unique day.</p>
                              </td>
                            </tr>

                            <tr>

                              <td>
                                <h4>THAINSTONE <span className="green-text">HOUSE</span></h4>
                                <p>Thainstone House Hotel is a 4-star hotel that lies within 44 acres of woodlands and private gardens.
This prominent hotel, with its notable 18th century history, has been devotedly updated to offer magnificent levels of hospitality.</p>
                              </td>
                            </tr>

                            <tr>

                              <td>
                                <h4>MELVILLE <span className="green-text">CASTLE</span></h4>
                                <p>“The Castle of your Dreams”. Let us help you arrange the most magical wedding day within one of Scotland’s finest romantic castles. Only 20 minutes from the city centre and set within 50 acres of beautiful grounds it is an ideal location for your dream wedding day.</p>
                              </td>
                            </tr>

                          </table>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}



export default VenuesPage;
