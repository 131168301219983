import React, { useEffect, useState } from 'react';
import { Link, NavLink, Redirect } from 'react-router-dom';
import { IntlActions } from 'react-redux-multilingual'
import Pace from 'react-pace-progress'

// Import custom components
import store from '../../../store';
import NavBar from "./common/NavBar";
import CartContainer from "../../../containers/CartContainer";
import TopBar from "./common/TopBar";
import LogoImage from "./common/logo";
import {connect} from "react-redux";

const HeaderOne = ({ logoName }) => {

  useEffect(() => {
    setTimeout(function() {
        document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
  })

  let searchForm = {
    searchTerm: ''
  };

  const [ isLoading, setLoading ] = useState(false);
  const [ searchInput, setSearchInput ] = useState(searchForm);
  const { searchTerm } = searchInput;

  const openNav = () => {
    var openSlide = document.getElementById('mySidenav');
    if(openSlide){
      openSlide.classList.add('open-side');
    }
  }

  const openSearch = () => {
    document.getElementById('search-overlay').style.display = 'block';
  }

  const closeSearch = () => {
    document.getElementById('search-overlay').style.display = 'none';
  }

  const onChange = e =>{
    setSearchInput({ ...searchInput, [e.target.name]: e.target.value });}


  const search = (e, searchTerm) => {
    e.preventDefault();
  }

  return (
    <div className="dark">
      <header id="sticky" className="sticky">
        {isLoading ? <Pace color="#27ae60"/> : null}
        <div className="mobile-fix-option"></div>
        {/*Top Header Component*/}
        <TopBar/>

        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="main-menu">
                <div className="menu-left">

                  <div className="brand-logo">
                    <LogoImage logo={logoName} />
                  </div>
                </div>
                <div className="menu-right pull-right">
                  {/*Top Navigation Bar Component*/}
                  <NavBar/>

                  <div>
                    <div className="icon-nav">
                      <ul>
                        <li className="onhover-div mobile-search">
                          <Link to={`${process.env.PUBLIC_URL}/search`}>
                          <div><img src={`${process.env.PUBLIC_URL}/assets/images/icon/search.png`} className="img-fluid white-icon" alt="" />
                            <i className="fa fa-search header-icon"></i></div></Link>
                        </li>

                        {/*Header Cart Component */}
                        <CartContainer/>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

              <div id="search-overlay" className="search-overlay">
                  <div>
                      <span className="closebtn" onClick={closeSearch} title="Close Overlay">×</span>
                      <div className="overlay-content">
                          <div className="container">
                              <div className="row">
                                  <div className="col-xl-12">
                                      <form onSubmit={e => {
                                        search(e, searchTerm);
                                      }}>
                                          <div className="form-group">
                                              <input type="text" className="form-control" name="searchTerm" placeholder="Search a Product" value={searchTerm} onChange={e => onChange(e)} />
                                          </div>
                                          <button type="submit" className="btn btn-primary"><i className="fa fa-search"></i></button>
                                      </form>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

    </div>
    )

};

export default connect(null,
    {  }
)(HeaderOne);
