import React, { Fragment, useEffect, useState } from 'react';
import {Helmet} from 'react-helmet'
import '../template/index.scss';
import PropTypes from 'prop-types';
import {connect} from "react-redux";

// import custom Components
import Service from "./common/service";
import BrandBlock from "./common/brand-block";
import NewProduct from "../template/new-product";
import Breadcrumb from "../template/breadcrumb";
import DetailsWithPrice from "./common/product/details-price";
import DetailsTopTabs from "./common/details-top-tabs";
import { addToCart, addToCartUnsafe, addToWishlist } from '../../actions/lists';
import { fetchProduct } from '../../actions/products';
import ImageZoom from './common/product/image-zoom'
import SmallImages from './common/product/small-image'


const ProductPage = ({
  fetchProduct,
  product: {
    product,
    symbol,
    loading
  },
  addToCart,
  addToCartUnsafe,
  addToWishlist,
  match
}) => {
  useEffect(() => {
    fetchProduct(match.params.slug)
  }, [fetchProduct, match.params.slug]);

  var slider1;
  var slider2;

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  })

  function filterClick() {
    document.getElementById("filter").style.left = "-15px";
  }

  function backClick() {
    document.getElementById("filter").style.left = "-365px";
  }

           var productsOptions = {
               slidesToShow: 1,
               slidesToScroll: 1,
               dots: false,
               arrows: true,
               fade: true
           };
           var productsNav = {
               slidesToShow: 3,
               swipeToSlide:true,
               arrows: false,
               dots: false,
               focusOnSelect: true
           };

  return (
      <div>


          {/*Section Start*/}
          {(product)?
            <Fragment>
            <Helmet>
                <title>Fireworx Scotland | {product.name}</title>
                <meta name="description" content={product.description} />
            </Helmet>
            {/*SEO Support End */}

            <Breadcrumb  parent={'Product'} title={product.name} />
          <section className="section-b-space">
              <div className="collection-wrapper">
                  <div className="container">
                      <div className="row">

                          <div className="col-sm-3 collection-filter" id="filter">
                              <div  className="collection-mobile-back pl-5">
                                  <span onClick={backClick}  className="filter-back">
                                      <i className="fa fa-angle-left" aria-hidden="true"></i> back
                                  </span>
                              </div>
                              <Service/>
                              {/*side-bar single product slider start*/}
                              <NewProduct category={product.categorySlug}/>
                              {/*side-bar single product slider end*/}
                          </div>
                          <div className="col-lg-9 col-sm-12 col-xs-12">
                              <div className="">
                                  <div className="row">
                                      <div className="col-xl-12">
                                          <div className="filter-main-btn mb-2">
                                              <span onClick={filterClick}  className="filter-btn" >
                                                  <i className="fa fa-filter" aria-hidden="true"></i> filter</span>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="row">
                                      <div className="col-lg-6 product-thumbnail">
                                          <div className="product-main-image">
                                            <img className="img-fluid" src={product.image} alt={product.name} />
                                          </div>

                                      </div>
                                      <DetailsWithPrice symbol={symbol} item={product} navOne={nav1} addToCartClicked={addToCart} BuynowClicked={addToCartUnsafe} addToWishlistClicked={addToWishlist} />
                                  </div>
                              </div>
                              <DetailsTopTabs item={product} />
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          </Fragment>
          : ''
            }
          {/*Section End*/}
      </div>
  )

}

ProductPage.propTypes = {
  fetchProduct: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  addToCart: PropTypes.func.isRequired,
  addToCartUnsafe: PropTypes.func.isRequired,
  addToWishlist: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  product: state.product
});

export default connect(mapStateToProps, {addToCart, addToCartUnsafe, addToWishlist, fetchProduct}) (ProductPage);
