import React from 'react';

import { Link } from 'react-router-dom';

import Breadcrumb from "../../../template/breadcrumb";
import { Helmet } from 'react-helmet'

const PhotographersPage = ({ }) => {

  return (
    <div>
        <Breadcrumb title="Photographers"/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12">
                        <h2>RECOMMENDED <span className="green-text">PHOTOGRAPHERS</span></h2>

                          <div>
                            <h4>CAPTURE THE MOMENT</h4>

                            <table className="table cart-table table-responsive-xs">
                              <tr>
                                <td><img src={`https://fireworxscotland.co.uk/wp-content/uploads/2014/08/simon-clubb.jpg`} /></td>
                                <td>
                                  <h4>SIMON <span className="green-text">CLUBB</span></h4>
                                  <h5 className="purple-text">PROVIDING ALL YOUR PHOTOGRAPHIC NEEDS</h5>
                                  <p>For weddings we love to get in on the action, each couple and their attendants make each wedding different and unique, so by utilising some modern, traditional & reportage styles i believe we can create a perfect set of images that truly tell the story of your big day.

I like to think of my clients as a small family, I enjoy the creating images for them to document the various chapters in the ever changing lives, whether it be their wedding, their baby bump, their new born child or their childs 1st day of school.</p>
                                </td>
                              </tr>

                              <tr>
                                <td><img src={`https://fireworxscotland.co.uk/wp-content/uploads/2014/08/donnamurray.jpg`} /></td>
                                <td>
                                  <h4>DONNA <span className="green-text">MURRAY</span></h4>
                                  <h5 className="purple-text">CATCHING ALL YOUR BIG SMILES</h5>
                                  <p>I studied Photography & film at Napier University in Edinburgh, all of my projects were photographed using medium and large format cameras and loved it, i began using digital when i became a press photographer at Aberdeen Journals a couple of months after leaving university. I learned the speed and ease of digital photography and worked under pressure with three deadlines a day. During my time at the journals I was short listed for ‘young scottish press photographer of the year’. I packed up my camera kit in 2009 and decided to fulfill my passion for travel photography.</p>
                                </td>
                              </tr>

                              <tr>
                                <td><img src={`https://fireworxscotland.co.uk/wp-content/uploads/2014/08/deeside-photographics.jpg`} /></td>
                                <td>
                                  <h4>DEESIDE <span className="green-text">PHOTOGRAPHIC</span></h4>
                                  <h5 className="purple-text">AWARD WINNING PHOTOGRAPHY</h5>
                                  <p>Deeside photographics have been leading the field in Wedding and Portrait Photography for over 25 years. Owned and managed by Award winning Photographer Logan Sangster LMPA LBIPP.We are based in the heart of Royal Deeside, in Banchory.!.</p>
                                </td>
                              </tr>


                            </table>

                          </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}



export default PhotographersPage;
