import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Slider from 'react-slick';

import { Link } from 'react-router-dom';

import Breadcrumb from "../../template/breadcrumb";
import {Slider2, Team4} from "../../../services/script";

const TermsPage = ({ }) => {

  return (
    <div>
        <Breadcrumb title="Terms And Conditions"/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12">
                        <h2><span className="green-text">TERMS</span> & CONDITIONS</h2>
                          <h3>PLEASE READ THESE TERMS OF SALE CAREFULLY BEFORE PLACING YOUR ORDER AND KEEP A COPY OF THESE TERMS AND YOUR ORDER FOR FUTURE REFERENCE</h3>
                          <div>
                            <h4>Fireworx Scotland Ltd Consumer Terms & Conditions of Sale</h4>
                          </div>

                          <div>
                            <h5>1. Format of the Contract</h5>
                            <p>1.1 These terms of sale apply to all goods supplied by Fireworx Scotland Ltd (the "Supplier").</p>
                            <p>1.2 No contract exists between you and the Supplier for the sale of any goods until the Supplier has received and accepted your order and the Supplier has received payment in full (in cleared funds). Once the Supplier does so, there is a binding legal contract between us.</p>
                            <p>1.3 By way of clarification, an acknowledgement of your order will be sent to you via e-mail when you place your order, but acceptance of your offer to buy the goods will not take place until after your payment is taken and you receive your acceptance e-mail. Also applies to over the couter and direct, delivery sales. It is at this point that a binding legal contract is created and any contract is subject to these Terms and Conditions.</p>
                            <p>1.4 The contract is subject to your right of cancellation (see below).</p>
                            <p>1.5 The Supplier may change these terms of sale without notice to you in relation to future sales.</p>
                          </div>

                          <div>
                            <h5>2. Description and price of the goods</h5>
                            <p> 2.1 The description and price of the goods you order will be as shown on the Supplier's website at the time you place your order.</p>
                            <p>2.2 The goods are subject to availability. If on receipt of your order the goods you have ordered are not available in stock, the Supplier will inform you as soon as possible and refund or re-credit you for any sum that has been paid by you or debited from your credit card for the goods.</p>
                            <p>2.3 Every effort is made to ensure that prices shown on the Supplier's website are accurate at the time you place your order. If an error is found, the Supplier will inform you as soon as possible and offer you the option of reconfirming your order at the correct price, or cancelling your order. If the Supplier does not receive an order confirmation within 14 days of informing you of the error, the order will be cancelled automatically. If you cancel, the Supplier will refund or re-credit you for any sum that has been paid by you or debited from your credit card for the goods.</p>
                            <p>2.4 In addition to the price, you may be required to pay a delivery charge for the goods.</p>
                          </div>

                          <div>
                            <h5>3. Payment</h5>
                            <p>3.1 Payment for the goods and delivery charges can be made by any method shown on the Supplier's website at the time you place your order. Payment shall be due before the delivery date and time for payment shall be of the essence.</p>
                            <p>3.2 There will be no delivery until cleared funds are received.</p>
                            <p>3.3 Payments shall be made by you without any deduction whether by way of set-off, counterclaim, discount, abatement or otherwise unless you have a valid court order requiring an amount equal to such deduction to be paid by the Supplier to you.</p>
                          </div>

                          <div>
                            <h5>4. Delivery</h5>
                            <p>4.1 The goods you order will be delivered to the address you give when you place your order, except that some deliveries are not made outside the United Kingdom.</p>
                            <p>4.2 Orders placed before 3.00 pm on a working day will be processed that day and will be delivered as per the requested delivery option provided no additional security checks are required and all stock items are available. (A working day is any day other than weekends and bank or other public holidays.)</p>
                            <p>4.3 If delivery cannot be made to your address for reasons under the Supplier's control the Supplier will inform you as soon as possible and refund or re-credit you for any sum that has been paid by you or debited from your credit card for delivery.</p>
                            <p>4.4 If you deliberately fail to take delivery of the goods (otherwise than by reason of circumstances under control of the Supplier) then without prejudice to any other right or remedy available to the Supplier , the Supplier may:</p>
                            <p>4.4.1 store the goods until actual delivery and charge you for reasonable costs (including insurance) of storage; or</p>
                            <p>4.4.2 sell the goods at the best readily obtainable price and (after deducting all reasonable storage and selling expenses) account to you for any excess over the price you agreed to pay for the goods or charge you for any shortfall below the price you agreed to pay for the goods.</p>
                            <p>4.5 If you fail to take delivery because you have cancelled your contract under the Distance Selling Regulations the Supplier shall refund or re-credit you within 30 days for any sum that has been paid by you or debited from your credit card for the goods, less any expenses incurred for failed delivery.</p>
                            <p>4.6 Every effort will be made to deliver the goods as soon as possible after your order has been accepted. However, the Supplier will not be liable for any loss or damage suffered by you through reasonable or unavoidable delay in delivery. In this case, the Supplier will inform you as soon as possible.</p>
                            <p>4.7 Time for delivery shall not be of the essence. The goods may be delivered by the Supplier in advance of the quoted delivery date.</p>
                            <p>4.8 Upon receipt of your order you will be asked to sign for the goods received in good condition. If you are unable to check the contents of the package at that moment in time please sign for the parcel as "UNCHECKED". Failure to do so may affect any warranty claims that you make thereafter.</p>
                          </div>

                          <div>
                            <h5>5 Risk/Title</h5>
                            <p>5.1 The goods are at your risk from the time of delivery</p>
                            <p>5.2 Ownership of the goods shall not pass to you until the Supplier has received in full (in cash or cleared funds) all sums due to it in respect of:</p>
                            <p>5.2.1 the goods, and</p>
                            <p>5.2.2 all other sums which are or which become due to the Supplier from you on any account.</p>
                            <p>5.3 The Supplier shall be entitled to recover payment for the goods notwithstanding that ownership of any of the goods has not passed from the Supplier.</p>
                          </div>

                          <div>
                            <h5>6. Title for Business Customers</h5>
                            <p>6.1 If you are a business customer until ownership of the goods has passed to you, you must:</p>
                            <p>6.1.1 store the goods (at no cost to the Supplier) separately from all your other goods and goods of any third party in such a way that they remain readily identifiable as the Supplier's property;</p>
                            <p>6.1.2 not destroy, deface or obscure any identifying mark or packaging on or relating to the goods; maintain the goods in satisfactory condition and keep them insured on the Supplier's behalf for their full price against all risks to the reasonable satisfaction of the Supplier. On request you shall produce the policy of insurance to the Supplier; and condition and keep them insured on the Supplier's behalf for their full price against all risks to the reasonable satisfaction of the Supplier. On request you shall produce the policy of insurance to the Supplier; and</p>
                            <p>6.1.3 hold the proceeds of the insurance referred to in condition 6.1.2 on trust for the Supplier and not mix them with any other money, nor pay the proceeds into an overdrawn bank account.</p>
                            <p>6.2 If you are a business customer your right to possession of the goods shall terminate immediately if:</p>
                            <p>6.2.1 you have a bankruptcy order made against you or make an arrangement or composition with your creditors, or otherwise take the benefit of any statutory provision for the time being in force for the relief of insolvent debtors, or (being a body corporate) convene a meeting of creditors (whether formal or informal), or enter into liquidation (whether voluntary or compulsory) except a solvent voluntary liquidation for the purpose only of reconstruction or amalgamation, or have a receiver and/or manager, administrator or administrative receiver appointed of its undertaking or any part thereof, or a resolution is passed or a petition presented to any court for your winding up or for the granting of an administration order in respect of you, or any proceedings are commenced relating to your insolvency or possible insolvency; or</p>
                            <p>6.2.2 you suffer or allow any execution, whether legal or equitable, to be levied on your property or obtained against you or you are unable to pay your debts within the meaning of section 123 of the Insolvency Act 1986 or you cease to trade; or</p>
                            <p>6.2.3 you encumber or in any way charge any of the goods.</p>
                          </div>

                          <div>
                            <h5>7. Your right of cancellation</h5>
                            <p>7.1 You have the right to cancel the contract at any time up to the end of 7 working days after you receive the goods (see below).</p>
                            <p>7.2 To exercise your right of cancellation, you must give written notice to the Supplier by letter or via the Returns on Line section of our website, giving details of the goods ordered and (where appropriate) their delivery. Notification by phone is not sufficient.</p>
                            <p>7.3 If you exercise your right of cancellation after the goods have been delivered to you, you will be responsible for returning the goods to the Supplier at your own cost. The goods must be returned to the address shown below. You must take reasonable care to ensure the goods are not damaged in the meantime or in transit.</p>
                            <p>7.4 Once you have notified the Supplier that you are cancelling the contract, the Supplier will refund or re-credit you within 30 days for any sum that has been paid by you or debited from your credit card for the goods.</p>
                            <p>7.5 If you do not return the goods as required, the Supplier may charge you a sum not exceeding the direct costs of recovering the goods.</p>
                            <p>7.6 You do not have the right to cancel the contract if the order is for computer software which has been unsealed by you, or for consumable goods which, by their nature, cannot be returned.</p>
                          </div>

                          <div>
                            <h5>8. Warranty</h5>
                            <p>8.1 All goods supplied by the Supplier are warranted free from defects for 12 months from the date of supply (unless otherwise stated). This warranty does not affect your statutory rights as a consumer. If new goods develop a defect during the 12 month warranty period, you should follow the Supplier's Returns procedure (see condition 12 below). In the event of a valid claim for a defect in the new goods, where clauses 8.3 do not apply, the Supplier will (at its option) either:</p>
                            <p>8.1.1 replace those goods, if the Supplier has available the same goods at the same price;</p>
                            <p>8.1.2 repair those goods; or</p>
                            <p>8.1.3 refund or re-credit you the sum you have paid for the relevant goods within 30 days of the date that the relevant goods are returned in accordance with the Supplier's Returns procedure (see condition 12 below).</p>
                            <p>8.2 This warranty does not apply to any defect in the goods arising from fair wear and tear, wilful damage, accident, negligence by you or any third party, use otherwise than as recommended by the Supplier, failure to follow the Supplier's instructions, or any alteration or repair carried out without the Supplier's approval.</p>
                            <p>8.3 If the goods supplied to you are damaged on delivery, you should notify the Supplier in writing via the e-mail address or fax number shown below within 7 days.</p>
                            <p>8.4 If the goods supplied to you develop a defect while under warranty or you have any other complaint about the goods, you should notify the Supplier in writing via the e-mail address or fax number shown below, as soon as possible, but in any event within 7 days of the date you discovered or ought to have discovered the damage, defect or complaint.</p>
                          </div>

                          <div>
                            <h5>9. Limitation of Liability</h5>
                            <p>9.1 the Supplier's total liability in contract, tort (including negligence or breach of statutory duty), misrepresentation, restitution or otherwise, arising in connection with the performance or contemplated performance of this agreement shall be limited to the price paid for the goods.</p>
                            <p>9.2 Nothing in these conditions excludes or limits the liability of the Supplier for death or personal injury caused by the Supplier's negligence or fraudulent misrepresentation.</p>
                            <p>9.3 If you are a business customer the Supplier shall not be liable to you for any indirect or consequential loss or damage (whether for loss of profit, loss of business, depletion of goodwill or otherwise), costs, expenses or other claims for consequential compensation whatsoever (howsoever caused) which arise out of or in connection with this agreement.</p>
                          </div>

                          <div>
                            <h5>10. Data Protection</h5>
                            <p>The Supplier will take all reasonable precautions to keep the details of your order and payment secure but unless the Supplier is negligent, the Supplier will not be liable for unauthorised access to information supplied by you.</p>
                          </div>

                          <div>
                            <h5>11. Applicable Law</h5>
                            <p>These terms of sale and the supply of the goods will be subject to English law and the English courts will have jurisdiction in respect of any dispute arising from the contract.</p>
                          </div>

                          <div>
                            <h5>12. Returns</h5>
                            <p>12.1 In particular, please note that any items returned to us which you claim to be faulty or incomplete are checked and verified by our technicians. Any returned items that are found not to be faulty or incomplete will be returned to you and we shall be entitled to charge you for the return carriage costs via your original payment method. In the event that your credit card has expired, or is declined we will hold the item(s) until full payment has been made for the return carriage.</p>
                            <p>12.2 Any items that you return to us are at your own risk, therefore we strongly advise all our customers to take reasonable care when returning any items to us for example, by ensuring the goods are correctly addressed, adequately packaged, and carried by a reputable carrier.</p>
                          </div>

                          <div>
                            <h5>13. Images</h5>
                            <p>Product images are for illustrative purposes only.</p>
                          </div>





                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

TermsPage.propTypes = {
  fetchPage: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired
};


export default connect(null, {  })(TermsPage)
