import React, { Fragment } from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../../../actions/auth';

const TopBar = ({ auth: { isAuthenticated, loading }, logout }) => {

  const guestLinks = (
    <ul className="onhover-show-div">
        <li>
            <Link to={`${process.env.PUBLIC_URL}/account/login`} data-lng="en">Login</Link>
        </li>
        <li>
            <Link to={`${process.env.PUBLIC_URL}/account/register`} data-lng="en">Register</Link>
        </li>
    </ul>
  )

  const authLinks = (
    <ul className="onhover-show-div">
        <li>
          <Link to={`${process.env.PUBLIC_URL}/account/dashboard`} data-lng="en">Dashboard</Link>
        </li>
        <li>
            <a onClick={() => logout()} >Logout</a>
        </li>
    </ul>
  )

  return (
    <div className="dark">
        <div className="top-header border-bottom-grey">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="header-contact">
                            <ul>
                                <li>{'topbar_title',  ' Fireworx Scotland Ltd' }</li>
                                <li><i className="fa fa-phone" aria-hidden="true"></i>{'Call Us'}: 01467 624872</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 text-right">
                        <ul className="header-dropdown">
                            <li className="mobile-wishlist compare-mobile"><Link to={`${process.env.PUBLIC_URL}/compare`}><i className="fa fa-random" aria-hidden="true"></i>{'compare'}</Link></li>
                            <li className="mobile-wishlist">{isAuthenticated ? <Link to={`${process.env.PUBLIC_URL}/wishlist`}><i className="fa fa-heart" aria-hidden="true"></i>{'wishlist'}</Link> : ''}</li>

                            <li className="onhover-dropdown mobile-account">
                                <i className="fa fa-user" aria-hidden="true"></i> {'My Account'}
                                <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )

}

TopBar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logout })(TopBar);
