import React, { useState } from 'react';
import { connect } from 'react-redux';
import { register } from '../../actions/auth';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import Breadcrumb from "../template/breadcrumb";

const Register = ({ register, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: ''
  });

  const { firstName, lastName, email, password } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    register({ firstName, lastName, email, password });
  }

  if (isAuthenticated) {
    return <Redirect to="/account/dashboard" />;
  }

  return (
    <div>
        <Breadcrumb title={'create account'}/>


        {/*Regsiter section*/}
        <section className="register-page section-b-space">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h3>create account</h3>
                        <div className="theme-card">
                            <form className="theme-form" onSubmit={e => onSubmit(e)}>
                                <div className="form-row">
                                    <div className="col-md-6">
                                        <label htmlFor="email">First Name</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="fname"
                                          name="firstName"
                                          value={firstName}
                                          onChange={e => onChange(e)}
                                          placeholder="First Name"
                                          required
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="review">Last Name</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="lname"
                                          name="lastName"
                                          value={lastName}
                                          onChange={e => onChange(e)}
                                          placeholder="Last Name"
                                          required
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-6">
                                        <label htmlFor="email">email</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="email"
                                          name="email"
                                          value={email}
                                          onChange={e => onChange(e)}
                                          placeholder="Email"
                                          required
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="review">Password</label>
                                        <input
                                          type="password"
                                          className="form-control"
                                          id="review"
                                          name="password"
                                          value={password}
                                          onChange={e => onChange(e)}
                                          placeholder="Enter your password"
                                          required
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-solid">create Account</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
  );

}

Register.propTypes = {
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { register })(Register);
