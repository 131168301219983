import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Slider from 'react-slick';

import { Link } from 'react-router-dom';

import Breadcrumb from "../../template/breadcrumb";
import {Slider2, Team4} from "../../../services/script";

const VouchersPage = ({ }) => {

  return (
    <div>
        <Breadcrumb title="Gift Vouchers"/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12">
                        <h2>GIFT VOUCHERS<span className="green-text">VOUCHERS</span></h2>
                        <div>
                          <h4>Our gift vouchers can be purchased in store</h4>
                            <p>At present we do not sell gift vouchers on our website</p>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

VouchersPage.propTypes = {
  fetchPage: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired
};


export default connect(null, {  })(VouchersPage)
