import {
  FILTER_SORT,
  FILTER_BRAND,
  FILTER_PRICE
} from './types';

// Filter by Price
export const filterPrice = value => ({
  type: FILTER_PRICE,
  value
});

// Filter sort
export const filterSort = sort_by => ({
  type: FILTER_SORT,
  sort_by
});
