import axios from 'axios'
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  AUTH_ERROR,
  USER_LOADED,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGOUT,
  RESET_PASSWORD,
  ERROR
} from './types';
import setAuthToken from '../utils/setAuthToken';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

// Submit user details in order form
export const submitDetails = ({ firstName, lastName, email }) => async dispatch => {
  const config = {
    headers: {
      'Content-Type' : 'application/json'
    }
  };
  const body = JSON.stringify({ firstName, lastName, email });
  try{
    const response = await axios.post('/api/users/placeorder', body, config);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: response.data
    });
    dispatch(loadUser());
  }catch(e){
    const errors = e.response.data.errors;
    if(errors){
      errors.forEach(error => toast.error(error.msg));
    }
    dispatch({
      type: REGISTER_FAIL
    });
  }
}

// Load current user
export const loadUser = () => async dispatch => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const response = await axios.get('/api/users/me');

    dispatch({
      type: USER_LOADED,
      payload: response.data
    });
  } catch(e){
    dispatch({
      type: AUTH_ERROR
    });
  }
}

// Login User
export const login = (email, password) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ email, password });

  try {
    const response = await axios.post('/api/users/login', body, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: response.data
    });
    dispatch(loadUser());
  } catch (e) {
    const errors = e.response.data.errors;
    if(errors){
      errors.forEach(error => toast.error(error.msg));
    }
    dispatch({
      type: LOGIN_FAIL
    })
  }
}

// Register User
export const register = ({ firstName, lastName, email, password }) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ firstName, lastName, email, password });

  try {
    const response = await axios.post('/api/users/', body, config);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: response.data
    });

  } catch (e) {
    const errors = e.response.data.errors;
    if(errors){
      errors.forEach(error => toast.error(error.msg));
    }
    dispatch({
      type: REGISTER_FAIL
    });
  }
}

// Logout User
export const logout = () => async dispatch => {
  localStorage.removeItem('state')
  try {
    await axios.post('/api/users/logout');
    dispatch({ type: LOGOUT });
  } catch (e){
    dispatch({
      type: 'LOGOUT_ERROR'
    });
  }
}

// Reset Password
export const resetPassword = email => async dispatch => {

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const body = JSON.stringify({email});
  try{
    await axios.post('/api/users/forgot-password', body, config);
    toast.success('Check your email! Your password has been reset');
    dispatch({ type: RESET_PASSWORD });
  }catch(e){
    // Handle Error
    const errors = e.response.data.errors;
    if(errors){
      errors.forEach(error => toast.error(error.msg));
    }
    dispatch({ type: ERROR });
  }
}
