import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';

// Checkout Components
import CheckOut from '../../checkout';
import Test from '../../checkout/test';
import StripeSuccess from '../../checkout/StripeSuccess';
import PaypalSuccess from '../../checkout/PaypalSuccess';

// Cart, Wishlist, Compare Components
import Compare from '../../compare';
import cartComponent from '../../cart';
import wishlist from '../../wishlist';

// Product Components
import ProductPage from '../../products/ProductPage';

// Collection Components
import CollectionByCategory from '../../collection/CollectionByCategory';
import SearchResults from '../../collection/SearchResults';

// Account Components
import Login from '../../accounts/Login';
import Register from '../../accounts/Register';
import Dashboard from '../../accounts/dashboard';
import MyOrders from '../../accounts/orders';
import ForgotPassword from '../../accounts/ForgotPassword';

// Pages
import Contact from '../../pages/Contact';
import InfoPage from '../../pages/InfoPage';
import Search from '../../pages/Search';

// Footer Pages
import AboutPage from '../../pages/footer-pages/AboutPage';
import CookiesPage from '../../pages/footer-pages/CookiesPage';
import DeliveryPage from '../../pages/footer-pages/DeliveryPage';
import OrderingPage from '../../pages/footer-pages/OrderingPage';
import PrivacyPage from '../../pages/footer-pages/PrivacyPage';
import ReturnsPage from '../../pages/footer-pages/ReturnsPage';
import TermsPage from '../../pages/footer-pages/TermsPage';
import VouchersPage from '../../pages/footer-pages/VouchersPage';
// Display Pages
// ** Fireworks **
import CelebrationPage from '../../pages/displays-pages/fireworks/CelebrationPage'
import CorporatePage from '../../pages/displays-pages/fireworks/CorporatePage'
import FestivalPage from '../../pages/displays-pages/fireworks/FestivalPage'
import MusicalPage from '../../pages/displays-pages/fireworks/MusicalPage'
import PublicPage from '../../pages/displays-pages/fireworks/PublicPage'
import QuietPage from '../../pages/displays-pages/fireworks/QuietPage'
import WeddingPage from '../../pages/displays-pages/fireworks/WeddingPage'
// ** Pyrotechnics **
import AnniversaryPyro from '../../pages/displays-pages/Pyrotechnics/AnniversaryPyro'
import BirthdayPyro from '../../pages/displays-pages/Pyrotechnics/BirthdayPyro'
import WeddingPyro from '../../pages/displays-pages/Pyrotechnics/WeddingPyro'
// ** Information **
import CarsPage from '../../pages/displays-pages/Information/CarsPage';
import EventPage from '../../pages/displays-pages/Information/EventPage';
import PhotographersPage from '../../pages/displays-pages/Information/PhotographersPage';
import PlannersPage from '../../pages/displays-pages/Information/PlannersPage';
import SafetyPage from '../../pages/displays-pages/Information/SafetyPage';
import ServicesPage from '../../pages/displays-pages/Information/ServicesPage';
import VenuesPage from '../../pages/displays-pages/Information/VenuesPage';

// Dev Routes
import PageBuilder from '../../pages/PageBuilder';

// Private Components
import PrivateRoute from './PrivateRoute'

const Routes = () => {
  return (
    <Fragment>
    <Switch>
      <Route exact path="/checkout" component={CheckOut} />
      <Route exact path="/test" component={Test} />
      <Route exact path="/success/stripe/:sessionId" component={StripeSuccess} />
      <Route exact path="/success/paypal/:paymentId" component={PaypalSuccess} />

      <Route exact path="/compare" component={Compare} />
      <Route exact path="/cart" component={cartComponent} />
      <Route exact path="/wishlist" component={wishlist} />

      <Route exact path="/product/:slug" component={ProductPage} />

      <Route exact path="/products/category/:slug" component={CollectionByCategory} />
      <Route exact path="/products/search/:searchTerm" component={SearchResults} />

      <Route exact path="/account/login" component={Login} />
      <Route exact path="/account/register" component={Register} />
      <Route exact path="/account/forgot-password" component={ForgotPassword} />

      <Route exact path="/contact-us" component={Contact} />
      <Route exact path="/fireworks/:slug" component={InfoPage} />
      <Route exact path="/pyrotechnics/:slug" component={InfoPage} />
      <Route exact path="/info/:slug" component={InfoPage} />
      <Route exact path="/search" component={Search} />

      <Route exact path="/about-us" component={AboutPage} />
      <Route exact path="/cookies-policy" component={CookiesPage} />
      <Route exact path="/delivery-information" component={DeliveryPage} />
      <Route exact path="/ordering-information" component={OrderingPage} />
      <Route exact path="/privacy-policy" component={PrivacyPage} />
      <Route exact path="/returns-policy" component={ReturnsPage} />
      <Route exact path="/terms-and-conditions" component={TermsPage} />
      <Route exact path="/gift-vouchers" component={VouchersPage} />

      <Route exact path="/displays/fireworks/celebration-life-fireworks" component={CelebrationPage} />
      <Route exact path="/displays/fireworks/corporate-fireworks" component={CorporatePage} />
      <Route exact path="/displays/fireworks/festival-fireworks" component={FestivalPage} />
      <Route exact path="/displays/fireworks/musical-fireworks" component={MusicalPage} />
      <Route exact path="/displays/fireworks/public-fireworks" component={PublicPage} />
      <Route exact path="/displays/fireworks/quiet-fireworks" component={QuietPage} />
      <Route exact path="/displays/fireworks/wedding-fireworks" component={WeddingPage} />

      <Route exact path="/displays/pyrotechnics/anniversary-pyrotechnics" component={AnniversaryPyro} />
      <Route exact path="/displays/pyrotechnics/birthday-pyrotechnics" component={BirthdayPyro} />
      <Route exact path="/displays/pyrotechnics/wedding-pyrotechnics" component={WeddingPyro} />

      <Route exact path="/displays/information/wedding-cars" component={CarsPage} />
      <Route exact path="/displays/information/event-management" component={EventPage} />
      <Route exact path="/displays/information/recommended-photographers" component={PhotographersPage} />
      <Route exact path="/displays/information/wedding-planners" component={PlannersPage} />
      <Route exact path="/displays/information/fireworks-safety" component={SafetyPage} />
      <Route exact path="/displays/information/wedding-related-services" component={ServicesPage} />
      <Route exact path="/displays/information/wedding-venues" component={VenuesPage} />


      <Route exact path="/pagebuilder" component={PageBuilder} />

      <PrivateRoute exact path="/account/dashboard" component={Dashboard} />
      <PrivateRoute exact path="/account/orders/my" component={MyOrders} />

    </Switch>
    </Fragment>
  )
};

export default Routes;
