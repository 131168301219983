import {
  FETCH_ORDER,
  CREATE_ORDER,
  FINALIZE_ORDER,
  CLEAR_STATE,
  MY_ORDERS,
  ERROR
} from './types';
import axios from 'axios';
import { orderSuccessEmail } from './emails';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';


export const createOrder = order => async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const body = JSON.stringify(order);
    try{
      const response = await axios.post('/api/orders/create', body, config);
      dispatch({
        type: CREATE_ORDER,
        payload: response.data
      });
    }catch(e){
      // Handle error
      const errors = e.response.data.errors;
      if(errors){
        errors.forEach(error => toast.error(error.msg));
      }
      dispatch({
        type: ERROR
      });
    }
};

export const finalizeOrder = ids => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const body = JSON.stringify(ids);
  try{
      const response = await axios.post('/api/billing/stripe/finalize', body, config);
      dispatch({
        type: FINALIZE_ORDER,
        payload: response.data
      });
      dispatch(orderSuccessEmail(ids));
  }catch(e){
    // handle error
    dispatch({
      type: ERROR
    });
  }
}

export const finalizePaypalOrder = ids => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const body = JSON.stringify(ids);
  try{
    const response = await axios.post('/api/billing/paypal/finalize', body, config);
    dispatch({
      type: FINALIZE_ORDER,
      payload: response.data
    });
    dispatch(orderSuccessEmail(ids));
  }catch(e){
    // Handle Error
    dispatch({
      type: ERROR
    });
  }
}

// Clear the order state
export const clearOrder = () => dispatch => {
  dispatch({
    type: CLEAR_STATE
  })
}

export const fetchMyOrders = () => async dispatch => {
  try {
    const response = await axios.get('/api/orders/my');
    dispatch({
      type: MY_ORDERS,
      payload: response.data
    });
  }catch(e){
    // Handle Error
    dispatch({
      type: ERROR
    });
  }
}
