import React from 'react';

import Breadcrumb from "../../../template/breadcrumb";
import { Helmet } from 'react-helmet'

const PublicPage = ({ }) => {

  return (
    <div>
    <Helmet>
        <title>Fireworx Scotland | Public Fireworks</title>
        <meta name="description" content="Whatever your event, from a traditional November 5th Bonfire Night a New Years Eve Celebration a Christmas Lights Switch On to a Summer Fireworks Concert, the Fireworks Scotland Ltd design team will create an original concept designed to suit your venue and most importantly, to suit you." />
    </Helmet>
        <Breadcrumb title="Public Fireworks"/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12">
                        <h2>PUBLIC <span className="green-text">FIREWORKS</span></h2>

                          <div>
                            <h4>DESIGNED TO AMAZE</h4>

                            <p>Whatever your event, from a traditional November 5th Bonfire Night a New Years Eve Celebration a Christmas Lights Switch On to a Summer Fireworks Concert, the Fireworks Scotland Ltd design team will create an original concept designed to suit your venue and most importantly, to suit you.</p>
                            <br/>

                            <p>Fireworx Scotland Ltd have worked alongside Aberdeen City Council, The Highland Council, Ardoe House, Meldrum House Hotel, Pittodrie House, Tor na Collie Hotel, Raemoir House Hotel, Fishers Hotel, Swallow Hotels, Waterside Inn, Tuffted Duck, Falkirk Wheel, Banchory Round Table, Banchory Lodge but to name a few.</p>
                            <br/>

                            <p>Fireworks Scotland Ltd. have extensive product knowledge and access to some of the finest fireworks available the world over.</p>
                            <br/>

                            <p className="green-text">Our design team is imaginative & is waiting to go to work for you.</p>
                            <br/>



                          </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}


export default PublicPage
