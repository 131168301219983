import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import {Link} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import { addToCart, addToWishlist, addToCompare } from '../../../actions/lists'
import { productsByCategory } from '../../../actions/products';
import {getVisibleproducts} from '../../../services';
import ProductListItem from "./product-list-item";
import toReadablePrice from '../../../utils/toReadablePrice';

const ProductListing = ({ productsByCategory, products: { products, loading, symbol }, slug, colSize, addToCart, addToCompare, addToWishlist, filters: { sortBy } }) => {
  console.log(slug)
  useEffect(() => {
    productsByCategory(slug);
  }, [productsByCategory, slug]);

  useEffect(() => {
    sortProducts(products, sortBy);
    products = products;
  })


  return (
      <div>
          <div className="product-wrapper-grid">
              <div className="container-fluid">
                  {products.length > 0 ?
                      <InfiniteScroll
                          dataLength={products.length} //This is important field to render the next data
                          next={() => console.log('Never fires')}
                          hasMore={false}
                          loader={<div className="loading-cls"></div>}
                          endMessage={""}
                      >
                          <div className="row product-flex">
                              { products.slice(0, products.length).map((product, index) =>
                                  <div className={`${colSize===3?'col-xl-3 col-md-6 col-grid-box':'col-lg-'+colSize}`} key={index}>
                                  <ProductListItem product={product} symbol={symbol}
                                                       onAddToCompareClicked={() => addToCompare(product)}
                                                       onAddToWishlistClicked={() => addToWishlist(product)}
                                                       onAddToCartClicked={addToCart} key={index}/>
                                                       <div className="text-center">
                                                         <h6>{product.name}</h6>
                                                         <h4>{symbol}{toReadablePrice(product.price)}</h4>
                                                       </div>
                                  </div>)
                              }
                          </div>
                      </InfiniteScroll>
                      :
                      <div className="row">
                          <div className="col-sm-12 text-center section-b-space mt-5 no-found" >
                              <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`} className="img-fluid mb-4" />
                              <h3>Sorry! Couldn't find the product you were looking For!!!    </h3>
                              <p>Please check if you have misspelt something or try searching with other words.</p>
                              <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid">continue shopping</Link>
                          </div>
                      </div>
                  }
              </div>
          </div>
      </div>
  )

}

ProductListing.propTypes = {
  products: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  productsByCategory: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
  addToWishlist: PropTypes.func.isRequired,
  addToCompare: PropTypes.func.isRequired
}

function sortProducts(products, sortBy){

  products.sort((product1, product2) => {
      if (sortBy === 'HighToLow') {
          return product2.price < product1.price ? -1 : 1;
      } else if (sortBy === 'LowToHigh') {
          return product2.price > product1.price ? -1 : 1;
      } else if (sortBy === 'Newest') {
          return product2.id < product1.id ? -1 : 1;
      } else if (sortBy === 'DescOrder') {
          return product1.name.localeCompare(product2.name);
      } else if (sortBy === 'AscOrder') {
          return product2.name.localeCompare(product1.name);
      } else{
          return product2.id > product1.id ? -1 : 1;
      }
  });
}

const mapStateToProps = (state) => {
    return {
      products: state.product,
      filters: state.filters
    }

}

export default connect(
    mapStateToProps, {addToCart, addToWishlist, addToCompare, productsByCategory}
)(ProductListing)
