import React from 'react';

import Breadcrumb from "../../../template/breadcrumb";
import { Helmet } from 'react-helmet'

const BirthdayPyro = ({ }) => {

  return (
    <div>
    <Helmet>
        <title>Fireworx Scotland | Birthday Pyrotechnics</title>
        <meta name="description" content="Birthday pyrotechnics have become very popular, we get enquiries every other day from customers wanting that extra “Wow” factor." />
    </Helmet>
        <Breadcrumb title="Birthday Pyrotechnics"/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12">
                        <h2>BIRTHDAY <span className="green-text">PYROTECHNICS</span></h2>

                          <div>
                            <h4>THAT EXTRA WOW FACTOR</h4>

                            <p>Birthday pyrotechnics have become very popular, we get enquiries every other day from customers wanting that extra “Wow” factor.</p>
                            <br/>

                            <p>Stage Pyrotechnics and Indoor Fireworks can be used to enhance key moments at a birthday celebration where maximum impact is required with small safety distances between the pyrotechnics and the spectators.</p>
                            <br/>

                            <p>We only use ultra reliable indoor fireworks that are handmade in the UK.</p>
                            <br/>

                            <p>We can guarantee your guests will be talking about your celebration and the added “Wow” factor for days and days after the event.</p>
                            <br/>

                            <p>From small smoke puffs to large stage maroons and even 6ft flames we can supply and run the whole Pyrotechnic event leaving you and your guests to enjoy the show.</p>
                            <br/>

                          </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}


export default BirthdayPyro;
