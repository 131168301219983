// React Dependancies
import React, { useState } from 'react';
import { Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'

// Payment Dependancies
import PaypalExpressBtn from 'react-paypal-express-checkout';
import { loadStripe } from '@stripe/stripe-js';
import toReadablePrice from '../../utils/toReadablePrice';

// Components
import Breadcrumb from "../template/breadcrumb";
import TabbedForm from './tabbed-form';

// Actions
import { removeFromWishlist } from '../../actions/lists';
import { clearOrder } from '../../actions/orders';
import { getCartTotal } from "../../services";

// Move these and require them in
import axios from 'axios';
const key = 'pk_test_51HQg3iAy0Vu8wLqjXNmALwERcsJd4dO4FWmPIvmqvgW4zhEDab3vnRlKrFk61NeGHZysphfK1SHmDzU9gkog8XBt00JMvDLjfS';
const stripePromise = loadStripe(key);

const CheckOut = ({cartItems, symbol, total, order, clearOrder, history}) => {

    // State vars
    const [ payment, setPayment ] = useState('stripe');
    const [ postage, setPostage ] = useState('delivery');
    const [ deliveryCost, setDeliveryCost ] = useState(1500);

    // Set the delivery cost
    const checkDelivery = (value) => {
      setPostage(value);
      if(value === 'delivery'){
        setDeliveryCost(1500);
      }else if(value === 'collect'){
        setDeliveryCost(0);
      }
    }

    // Set the payment method, changes the button
    const checkhandle = (value) => {
      setPayment(value);
    }

    const submitForm = (e) => {
      // Prevent browser refresh
      e.preventDefault();
      // TODO Submit form
      // Update state with order number
      // Hide or retract form
      // Present payment options
      // Handle error if email already exists
    }

  const stripeClick = async(event)=>{
    // Get Stripe.js instance
    const stripe = await stripePromise;

    // Call your backend to create the Checkout Session
    const response = await axios.post('/api/billing/payment', { cartItems, deliveryCost });

    const session = response.data;

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  }

  // Toggle the payment options button
  // Initially do not show the payments
  const [ showPayment, setShowPayment ] = useState(false);

  // Paypal btn config
  const client = {
    sandbox: 'ATqv_XumG8VAjWSei3Qo0Rqqql_RJ1634V4zmZXxr3TsmBqNk4IEenJDb0TYObNw8pg_nABzcyNKJSbO'
  };
  const onCancel = () => {
    alert('The payment was cancelled')
  }
  const onError = () => {
    alert('The payment has been declined');
  }
  const onSuccess = (payment) => {
    console.log(payment);
    history.push(`/success/paypal/${payment.paymentID}`);
  }


    return (
      <div>

          {/*SEO Support*/}
          <Helmet>
              <title>Fireworx Scotland | CheckOut Page</title>
              <meta name="Check out your items" content="Checkout" />
          </Helmet>
          {/*SEO Support End */}

          <Breadcrumb  title={'Checkout'}/>

          <section className="section-b-space">
              <div className="container padding-cls">
                  <div className="checkout-page">
                      <div className="checkout-form">
                          <form>
                              <div className="checkout row">
                                  <div className="col-lg-6 col-sm-12 col-xs-12">
                                      <TabbedForm postage={postage} togglePayment={() => setShowPayment(true)}/>
                                  </div>
                                  <div className="col-lg-6 col-sm-12 col-xs-12">
                                      <div className="checkout-details">
                                          <div className="order-box">
                                              <div className="title-box">
                                                  <div>Product <span> Total</span></div>
                                              </div>
                                              <ul className="qty">
                                                  {cartItems.map((item, index) => {
                                                      return <li key={index}>{item.name} × {item.qty} <span>{symbol} {toReadablePrice(item.price*item.qty)}</span></li> })
                                                  }
                                              </ul>
                                              <ul className="sub-total">
                                                  <li>Subtotal <span className="count">{symbol}{toReadablePrice(total)}</span></li>
                                                  <li>Shipping <div className="shipping">
                                                  <ul>
                                                      <li>
                                                      <div className="shopping-option">
                                                          <input type="radio" name="shipping-group" id="free-shipping" defaultChecked={true} onClick={() => checkDelivery('delivery')} />
                                                              <label htmlFor="free-shipping">Next Day Delivery - £15</label>
                                                      </div>
                                                      </li>
                                                      <li>
                                                      <div className="shopping-option">
                                                          <input type="radio" name="shipping-group" id="local-pickup" onClick={() => checkDelivery('collect')} />
                                                              <label htmlFor="local-pickup">Collect in Store</label>
                                                      </div>
                                                      </li>
                                                      </ul>
                                                  </div>
                                                  </li>
                                              </ul>

                                              <ul className="total">
                                                  <li>Total <span className="count">{symbol}{toReadablePrice(total+deliveryCost)}</span></li>
                                              </ul>
                                          </div>

                                          {(showPayment) ? <div className="payment-box">
                                              <div className="upper-box">
                                                  <div className="payment-options">
                                                      <ul>
                                                          <li>
                                                              <div className="radio-option stripe">
                                                                  <input type="radio" name="payment-group" id="payment-2" defaultChecked={true} onClick={() => checkhandle('stripe')} />
                                                                  <label htmlFor="payment-2">Credit/Debit Card</label>
                                                              </div>
                                                          </li>
                                                          <li>
                                                              <div className="radio-option paypal">
                                                                  <input type="radio" name="payment-group" id="payment-1" onClick={() => checkhandle('paypal')} />
                                                                      <label htmlFor="payment-1">PayPal<span className="image"><img src={`${process.env.PUBLIC_URL}/assets/images/paypal.png`} alt=""/></span></label>
                                                              </div>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </div>
                                              {(total !== 0)?
                                              <div className="text-right">
                                                  {(payment === 'stripe')? <button type="button" className="btn-solid btn" onClick={stripeClick} >Place Order</button> :
                                                  <PaypalExpressBtn env={'sandbox'} client={client} currency={'GBP'} total={(total+deliveryCost)/100} onError={onError} onSuccess={onSuccess} onCancel={onCancel} />
                                                }
                                              </div>
                                              : ''}
                                          </div> : ''}
                                      </div>
                                  </div>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </section>
      </div>
    )

};


const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.product.symbol,
    total: getCartTotal(state.cartList.cart),
    order: state.order
})

export default connect(
    mapStateToProps,
    { removeFromWishlist, clearOrder }
)(CheckOut)
