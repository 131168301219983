import {
  ADD_TO_CART,
  ADD_TO_COMPARE,
  ADD_TO_WISHLIST,
  REMOVE_FROM_CART,
  REMOVE_FROM_COMPARE,
  REMOVE_FROM_WISHLIST,
  CLEAR_CART,
  DECREMENT_QTY,
  FETCH_WISHLIST,
  ERROR
} from './types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';

// Wrapper for add to cart
export const addToCartUnsafe = (product, qty) => ({
  type: ADD_TO_CART,
  product,
  qty
});

// Add to cart
export const addToCart = (product, qty) => dispatch => {
  toast.success('Item Added to Cart');
    dispatch(addToCartUnsafe(product, qty));
};

// Add to cart and remove from wishlist
export const addToCartAndRemoveWishlist = (product, qty) => dispatch => {
  toast.success('Item Added to Cart');
    dispatch(addToCartUnsafe(product, qty));
    dispatch(removeFromWishlist(product._id));
};

// Remove from cart
export const removeFromCart = _id => dispatch => {
  toast.error('Item Removed from Cart');
  dispatch({
    type: REMOVE_FROM_CART,
    _id
  });
};

// Increment item quantity in cart
export const incrementQty = (product, qty) => dispatch => {
  toast.success('Item Added to Cart');
  dispatch(addToCartUnsafe(product, qty));
}

// Decrement item quantity in cart
export const decrementQty = productId => dispatch => {
  toast.warn('Item Removed From Cart!');

  dispatch({
    type: DECREMENT_QTY,
    productId
  })
}

// Clear Cart
export const clearCart = () => dispatch => {
  dispatch({
    type: CLEAR_CART
  })
}

// Wrapper for wishlist
export const addToWishlistUnsafe = product => ({
  type: ADD_TO_WISHLIST,
  product
});

// Add to wishlist
export const addToWishlist = product => async dispatch => {

  try{

    const response = await axios.post(`/api/wishlist/add/${product._id}`);
    console.log(response)
    if(response){
      toast.success('Item Added to Wishlist');
      dispatch(addToWishlistUnsafe(product));
    }
  }catch(e){
    dispatch({
      type: ERROR
    })
  }

};

// Remove from wishlist
export const removeFromWishlist = _id => async dispatch => {
  try{
    const response = await axios.delete(`/api/wishlist/remove/${_id}`);
    if(response){
      console.log(response);
      toast.error('Item Removed from Wishlist');
      dispatch({
        type: REMOVE_FROM_WISHLIST,
        payload: _id
      });
    }
  }catch(e){
    dispatch({
      type: ERROR
    });
  }

};

// Fetch Wishlist from API
export const fetchWishlist = () => async dispatch => {
  try {
    const response = await axios.get('/api/wishlist/me');
    console.log(response)

    dispatch({
      type: FETCH_WISHLIST,
      payload: response.data
    });

  } catch(e){
    dispatch({
      type: ERROR
    })
  }
}

// Add to Compare
export const addToCompare = product => (dispatch) => {
    toast.success("Item Added to Compare");
    dispatch(addToCompareUnsafe(product))

}

// Add to compare wrapper
export const addToCompareUnsafe = product => ({
    type: ADD_TO_COMPARE,
    product
});

// Remove from compare
export const removeFromCompare = product_id => ({
    type: REMOVE_FROM_COMPARE,
    product_id
});
