import {
  PRODUCTS_BY_CATEGORY,
  FETCH_PRODUCT,
  FETCH_FEATURED,
  SEARCH_PRODUCTS
} from '../actions/types';


const initialState = {
    products: [],
    symbol: '£',
    product: null,
    loading: true
};

const productReducer = (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case PRODUCTS_BY_CATEGORY:
          return {
            ...state,
            products: payload,
            loading: false
          }
        case FETCH_FEATURED:
          return {
            ...state,
            products: payload,
            loading: false
          }
        case FETCH_PRODUCT:
          return {
            ...state,
            product: payload,
            loading: false
          }
        case SEARCH_PRODUCTS:
          return {
            ...state,
            products: payload,
            loading: false
          }
        default:
            return state;
    }
};
export default productReducer;
