import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Slider from 'react-slick';

import { fetchPage } from '../../actions/pages';

import Breadcrumb from "../template/breadcrumb";
import {Slider2, Team4} from "../../services/script";

const InfoPage = ({ fetchPage, page: { page, loading }, match }) => {
  useEffect(() => {
    fetchPage(match.params.slug);
  }, [fetchPage, match.params.slug]);

  return loading || page === null ? (
    <div>Loading</div>
  ) : (
    <div>
        <Breadcrumb title={page.title}/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="banner-section">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/about/about%20us.jpg`} className="img-fluid" alt=""/>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <h4>{page.title}</h4>
                        <div dangerouslySetInnerHTML={{ __html: page.body }}>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

InfoPage.propTypes = {
  fetchPage: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  page: state.page
});

export default connect(mapStateToProps, { fetchPage })(InfoPage)
