import React from 'react';

import Breadcrumb from "../../../template/breadcrumb";
import { Helmet } from 'react-helmet'

const WeddingPage = ({ }) => {

  return (
    <div>
    <Helmet>
        <title>Fireworx Scotland | Wedding Fireworks</title>
        <meta name="description" content="With a network of professionally trained pyrotechnicians, we are able to offer a national wedding display service covering all of the United Kingdom. Above all we design an event that you and your guests will always remember." />
    </Helmet>
        <Breadcrumb title="Wedding Fireworks"/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12">
                        <h2>WEDDING <span className="green-text">FIREWORKS</span></h2>

                          <div>
                            <h4>REMEMBER THE MOMENT</h4>

                            <p>Fireworks have now become synonymous with weddings, adding that extra dimension to the bride and groom’s special day. Fireworks Scotland Ltd. is one of the UK’s leading wedding fireworks display specialists, with vast experience in this area of the entertainments industry.</p>
                            <br/>

                            <p>With a network of professionally trained pyrotechnicians, we are able to offer a national wedding display service covering all of the United Kingdom. Above all we design an event that you and your guests will always remember.</p>
                            <br/>

                            <p>“Bespoke” shows can range from 5 minutes to 20 minutes, and are designed to suit the event and location. We can even provide quiet fireworks for displays in urban or sensitive locations.</p>
                            <br/>

                            <p>Fireworx Scotland Ltd. have established an outstanding reputation for creating the most imaginative and
beautiful firework displays. We have managed this through a combination of professional expertise, creative excellence and the use of high quality innovative materials.</p>
                            <br/>

                          </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}


export default WeddingPage;
