import React, { useState } from 'react';
import Breadcrumb from "../template/breadcrumb";
import { connect } from 'react-redux';
import { resetPassword } from '../../actions/auth';

const ForgotPassword = ({ resetPassword }) => {

  let formInit = {
    emailAddress: ''
  };

  const [ formInput, setFormInput ] = useState(formInit);
  const { emailAddress } = formInput;

  const onChange = e =>{
    setFormInput({ ...formInput, [e.target.name]: e.target.value });}


  return (
    <div>
        <Breadcrumb title={'forgot password'}/>


        {/*Forget Password section*/}
        <section className="pwd-page section-b-space">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <h2>Reset Your Password</h2>
                        <form className="theme-form" onSubmit={e => {
                          e.preventDefault();
                          resetPassword(emailAddress);
                          setFormInput(formInit);
                        }}>
                            <div className="form-row">
                                <div className="col-md-12">
                                    <input type="text" className="form-control" name="emailAddress" value={emailAddress}
                                           placeholder="Enter Your Email" required="" onChange={e => onChange(e)} />
                                </div>
                                <button type="submit" className="btn btn-solid">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>

    </div>
  )
}

export default connect(null, resetPassword)(ForgotPassword)
