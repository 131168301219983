import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { finalizePaypalOrder } from '../../actions/orders';
import { clearCart } from '../../actions/lists';

const PaypalSuccess = ({ order, finalizePaypalOrder, clearCart, match }) => {
  useEffect(() => {
    const ids = {
        paymentId: match.params.paymentId,
        orderId: order._id
    }
     finalizePaypalOrder(ids);
     clearCart();
  }, []);

  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  var current = new Date();
  let CheckDate = current.toLocaleDateString("en-US", options).toString()


  const symbol = "£";
  const items = order.products


  return (
      (order.payment)?
      <div>
          <section className="section-b-space light-layout">
              <div className="container">
                  <div className="row">
                      <div className="col-md-12">
                          <div className="success-text">
                              <i className="fa fa-check-circle" aria-hidden="true"></i>
                              <h2>thank you</h2>
                              <p>Payment Is Has Been Received Order Placed Successfully</p>
                              <p>Transaction ID: {order.payment.sessionId}</p>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="section-b-space">
              <div className="container">
                  <div className="row">
                      <div className="col-lg-6">
                          <div className="product-order">
                              <h3>your order details</h3>
                              {items.map((item, index) => {
                              return <div className="row product-order-detail" key={index}>
                                          <div className="col-3">
                                              <img src={item.image | null} alt="" className="img-fluid" />
                                          </div>
                                          <div className="col-3 order_detail">
                                              <div>
                                                  <h4>product name</h4>
                                                  <h5>{item.name}</h5>
                                              </div>
                                          </div>
                                          <div className="col-3 order_detail">
                                              <div>
                                                  <h4>quantity</h4>
                                                  <h5>{item.qty}</h5>
                                              </div>
                                          </div>
                                          <div className="col-3 order_detail">
                                              <div>
                                                  <h4>price</h4>
                                                  <h5>{symbol}{(item.price/100)*item.qty}</h5>
                                              </div>
                                          </div>
                                      </div>
                              })}
                              <div className="total-sec">
                                  <ul>
                                      <li>subtotal <span>{symbol}{order.subtotal/100}</span></li>
                                      <li>shipping <span>{symbol}{order.shippingCost/100}</span></li>
                                      <li>tax(GST) <span>£0</span></li>
                                  </ul>
                              </div>
                              <div className="final-total">
                                  <h3>total <span>{symbol}{order.total/100}</span></h3>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-6">
                          <div className="row order-success-sec">
                              <div className="col-sm-6">
                                  <h4>summery</h4>
                                  <ul className="order-detail">
                                      {(order.payment.status === 'paid')?
                                          <div>
                                      <li>payer ID: {order.payment.method}</li>
                                      <li>payment ID: {order._id}</li></div>
                                          :
                                      <li>Order ID: {order._id}</li> }

                                      <li>Order Date: {CheckDate}</li>
                                      <li>Order Total: {symbol}{order.total/100}</li>
                                  </ul>
                              </div>
                              <div className="col-sm-6">
                                  <h4>shipping address</h4>
                                  <ul className="order-detail">
                                      <li>{order.customerDetails.firstName} {order.customerDetails.lastName}</li>
                                      <li>{order.deliveryAddress.line1}</li>
                                      <li>{order.deliveryAddress.line2}</li>
                                      <li>{order.deliveryAddress.town}</li>
                                      <li>{order.deliveryAddress.county}</li>
                                      <li>{order.deliveryAddress.postcode}</li>
                                  </ul>
                              </div>

                              <div className="col-sm-12 payment-mode">
                                  <h4>payment method</h4>
                                  <p>{ order.payment.method }</p>
                              </div>

                              {(order.shippingCost) === 0 ? <div className="col-md-12">
                                  <div className="delivery-sec">
                                      <h3>Collect at Our Store</h3>
                                      <h5>Please allow at least 4 hours before collecting</h5>
                                      <address>
                                        {order.deliveryAddress.line1}<br/>
                                        {order.deliveryAddress.line2}<br/>
                                        {order.deliveryAddress.town}<br/>
                                        {order.deliveryAddress.county}<br/>
                                        {order.deliveryAddress.postcode}
                                      </address>
                                  </div>
                              </div> : ''}

                          </div>
                      </div>

                  </div>
              </div>
          </section>
      </div>
      :
      <section className="p-0">
          <div className="container">
              <div className="row">
                  <div className="col-sm-12">
                      <div className="error-section">
                          <h1>404</h1>
                          <h2>page not found</h2>
                          <a href="index.html" className="btn btn-solid">back to home</a>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  )
}

const mapStateToProps = state => {
  return {
    order: state.order.order
  }
}

export default connect(mapStateToProps, { finalizePaypalOrder, clearCart })(PaypalSuccess);
