// Cart
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const DECREMENT_QTY = 'DECREMENT_QTY';
export const CLEAR_CART = 'CLEAR_CART';
// Wishlist
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';
export const FETCH_WISHLIST = 'FETCH_WISHLIST';
// Compare products
export const ADD_TO_COMPARE = 'ADD_TO_COMPARE';
export const REMOVE_FROM_COMPARE = 'REMOVE_FROM_COMPARE';
// Authentication actions
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const AUTH_ERROR = 'AUTH_ERROR';
export const USER_LOADED = 'USER_LOADED';
export const LOGOUT = 'LOGOUT';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const RESET_PASSWORD = 'RESET_PASSWORD';
// Pages
export const FETCH_PAGE = 'FETCH_PAGE';
export const PAGE_ERROR = 'PAGE_ERROR';
// Filters
export const FILTER_BRAND = 'FILTER_BRAND';
export const FILTER_PRICE = 'FILTER_PRICE';
export const FILTER_SORT = 'FILTER_SORT';
// Products
export const PRODUCTS_BY_CATEGORY = 'PRODUCTS_BY_CATEGORY';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const RELATED_PRODUCTS = 'RELATED_PRODUCTS';
export const FETCH_FEATURED = 'FETCH_FEATURED';
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
// Orders
export const FETCH_ORDER = 'FETCH_ORDER';
export const CREATE_ORDER = 'CREATE_ORDER';
export const FINALIZE_ORDER = 'FINALIZE_ORDER';
export const CLEAR_STATE = 'CLEAR_STATE';
export const MY_ORDERS = 'MY_ORDERS';
// Emails
export const ORDER_SUCCESS_EMAIL = 'ORDER_SUCCESS_EMAIL';
export const SIGNUP_EMAIL = 'SIGNUP_EMAIL';
export const NEWSLETTER_EMAIL = 'NEWSLETTER_EMAIL';
export const SUBMIT_CONTACT_FORM = 'SUBMIT_CONTACT_FORM';
// Generic Error
export const ERROR = 'ERROR';
