import { combineReducers } from 'redux';
import cartReducer from './cart';
import wishlistReducer from './wishlist';
import compareReducer from './compare';
import productReducer from './products';
import authReducer from './auth';
import pageReducer from './pages';
import filterReducer from './filters';
import orderReducer from './orders';
import { LOGOUT } from '../actions/types';


const appReducer = combineReducers({
  product: productReducer,
  cartList: cartReducer,
  wishlist: wishlistReducer,
  compare: compareReducer,
  auth: authReducer,
  page: pageReducer,
  filters: filterReducer,
  order: orderReducer
});

const rootReducer = (state, action) => {
  if(action.type === LOGOUT){
    state = undefined
  }

  return appReducer(state, action);
}

export default rootReducer;
