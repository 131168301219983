import React from 'react';

import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'

import Breadcrumb from "../../../template/breadcrumb";

const QuietPage = ({ }) => {

  return (
    <div>
    <Helmet>
        <title>Fireworx Scotland | Quiet Fireworks</title>
        <meta name="description" content="To meet the growing demand for quieter fireworks for weddings, corporate events and private parties we travelled to China in search of the highest quality materials. And we found them….." />
    </Helmet>
        <Breadcrumb title="Quiet Fireworks"/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12">
                        <h2>QUIET <span className="green-text">FIREWORKS</span></h2>

                          <div>
                            <h4>BEAUTIFUL & DRAMATIC</h4>

                            <p>Many venues that host regular firework displays such as hotels and stately homes require quieter fireworks to limit the impact on the local environment, local residents and occasionally livestock.</p>
                            <br/>

                            <p>To meet the growing demand for quieter fireworks for weddings, corporate events and private parties we travelled to China in search of the highest quality materials. And we found them…..</p>
                            <br/>

                            <p>A quiet firework display from Fireworx Scotland Ltd does not lose any of its impact. Although you do not get the traditional loud aerial burst you do get a peaceful, beautiful and dramatic show and we guarantee you won’t miss the bangs.</p>
                            <br/>

                            <p>We do not compromise on quality for price and are always looking for the next new and special thing to have for our clients.</p>
                            <br/>

                            <p>Please <Link to={`${process.env.PUBLIC_URL}/contact-us`} className="green-text">contact us</Link> for further details or if you wish us to discuss the possiblity of using low noise fireworks at your venue.</p>
                            <br/>

                          </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}


export default QuietPage;
