import React from 'react';
import axios from 'axios';
import StripeCheckout from 'react-stripe-checkout'

const toPennies = amount => amount * 100;

const successPayment = data => {
  alert('payment success');
  console.log(data);
}

const errorPayment = data => {
  alert('Pay fail');
  console.log(data);
}

const onToken = (amount, description) => token =>
  axios.post('/api/billing/stripe',
    {
      description,
      source: token.id,
      currency: 'GBP',
      amount: toPennies(amount)
    })
    .then(successPayment)
    .catch(errorPayment);

const Test = ({ name, description, amount }) =>
  <StripeCheckout
    name={'NAME GOES HERE'}
    description={'DESCRIPTION HERE'}
    panelLabel="panelLabel"
    amount={toPennies(10.99)}
    email="email@email.com"
    token={onToken(10.99, 'description')}
    currency={'GBP'}
    stripeKey={'pk_test_51HQg3iAy0Vu8wLqjXNmALwERcsJd4dO4FWmPIvmqvgW4zhEDab3vnRlKrFk61NeGHZysphfK1SHmDzU9gkog8XBt00JMvDLjfS'}
  />
  export default Test;
