import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Slider from 'react-slick';

import { Link } from 'react-router-dom';

import Breadcrumb from "../../template/breadcrumb";
import {Slider2, Team4} from "../../../services/script";

const DeliveryPage = ({ }) => {

  return (
    <div>
        <Breadcrumb title="Delivery Information"/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12">
                        <h2>Delivery <span className="green-text">Information</span></h2>
                        <div>
                          <h4>UK Deliveries</h4>
                          <p>All orders placed before <strong>1pm</strong> Monday - Friday (exc. bank holidayys) are dispatched <span className="text-danger">the same day</span></p>
                          <p>Our standard courier delivery service is <strong>£15</strong></p>
                          <p>Most orders are delivered <strong>next day</strong> however this <span className="text-danger">cannot be guaranteed</span></p>
                          <br/>
                          <h5>IMPORTANT: Delivery schedules do NOT include weekends.</h5>
                          <p>A surcharge may apply to large/bulky orders</p>
                        </div>
                        <div>
                          <h4>Delivery Questions and Answers</h4>
                            <h5>How do I track my order?</h5>
                              <p>You can track the progress of your order by logging into your <Link to={`${process.env.PUBLIC_URL}/account/dashboard`} className="green-text" >Dashboard</Link> and clicking on "My Orders".</p>
                              <p>Note: All items are fully insured in transit. If you have any questions relating to the status or whereabouts of an order please <Link to={`${process.env.PUBLIC_URL}/contact-us`} className="green-text" >contact us</Link></p>
                            <br/>
                            <h5>I live in the Channel Islands - Can you remove VAT?</h5>
                              <p>Yes - please place your order as normal and <Link to={`${process.env.PUBLIC_URL}/contact-us`} className="green-text" >contact us</Link></p>
                            <br/>
                            <h5>Can you deliver to a BFPO address?</h5>
                              <p>At present we are unable to deliver to BFPO addresses.</p>
                            <br/>
                            <h5>Do you have to deliver to the card-holders address?</h5>
                              <p>No. You can choose an alternative delivery address when you place your order. We reserve the right to ship your only to the card holders address where the orders is particularly large. In this case we will always contact you and arrange a suitable delivery date. </p>
                            <br/>
                            <h5>Can you deliver my order to multiple addresses?</h5>
                              <p>Unfortunately, our system does not allow you to send different parts of your order to separate addresses. You will need to place a separate order for each address you wish to deliver to.</p>
                            <br/>
                            <h5>I've placed my order - can I change the delivery address?</h5>
                              <p>This will depend largely on the progress of the order through our system. Once your order status has been changed to 'shipped' we are then unable to change the delivery address as the goods are being handled by our Courier Company. </p>
                              <p>For security all request for changes of address must be made by calling us during normal working hours. You'll find our <Link to={`${process.env.PUBLIC_URL}/contact-us`} className="green-text" >contact details</Link> here.</p>
                            <br/>
                        </div>
                        <div>
                          <h4>In Store Collection</h4>
                            <p>We offer free 'click and collect' service throughout the year.</p>
                            <p>Simply place your order as normal and select the collect in store option as you complete the checkout.</p>
                            <p>You'll find our <Link to={`${process.env.PUBLIC_URL}/contact-us`} className="green-text" >opening hours here</Link></p>
                            <p>Please allow at least 4 hours for us to prepare your order</p>
                        </div>
                        <div>
                          <h4>International Deliveries</h4>
                            <p>Unfortunately we are currently unable to deliver our fireworks outside of the United Kingdom.</p>
                          </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

DeliveryPage.propTypes = {
  fetchPage: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired
};


export default connect(null, {  })(DeliveryPage)
