import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Slider from 'react-slick';

import { Link } from 'react-router-dom';

import Breadcrumb from "../../template/breadcrumb";
import {Slider2, Team4} from "../../../services/script";

const OrderingPage = ({ }) => {

  return (
    <div>
        <Breadcrumb title="Ordering"/>
        {/*about section*/}
        <section className="about-page  section-b-space">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12">
                        <h2>HOW TO <span className="green-text">ORDER FROM US</span></h2>
                        <div>
                          <h4>Ordering from us</h4>
                            <p>We want to make as easy a possible for you to place an order with us.</p>
                            <p>When you click '<strong>add to basket</strong>' you'll be able to see the running total of your order on the top right of the page. Clicking on that total will take you to your basket page where we estimate your delivery costs and you're ready to begin checkout.</p>
                            <p>We've made checkout as simple as possible. You don't need to create an account, but if you do decide to give us your email address we'll store your order details, and give you online tracking of your order.</p>
                            <p>During the payment process, you can specify if you'd like to use your credit or debit card, or you can switch to your paypal account and pay us that way.</p>
                            <br/>
                          <h5>After You Have Placed Your Order</h5>
                            <p>Once you've confirmed your order, we'lll send you an e-mail confirming the details - let is know straight away if you need to change something, as we can't change it once we start processing your order.</p>
                            <p>If you have completed order by 1pm we endevour to dispatch it in time for next working day delivery. After 1pm, it will ship the next working day. Orders shipped on Friday will be delivered Monday, as we don't dispatch or deliver at the weekend or on bank holidays. </p>
                            <p>Once we ship your order, we'll send you another e-mail to let you know, which will contain details of how to track your order as it makes its way to you</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

OrderingPage.propTypes = {
  fetchPage: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired
};


export default connect(null, {  })(OrderingPage)
