import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';
import {Link} from 'react-router-dom'
import { submitDetails } from '../../actions/auth';
import { createOrder } from '../../actions/orders';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const TabbedForm = ({ createOrder, submitDetails, isAuthenticated, user, togglePayment, cartItems, postage }) => {

      let detailsForm = {
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
      }
      if(isAuthenticated && user){
        detailsForm = {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phone: user.phone
        }
      }

      let addressForm = {
        line1: '',
        line2: '',
        town: '',
        county: '',
        postcode: '',
        country: ''
      }

      // Initialize formData in state
      const [ detailsFormData, setDetailsForm ] = useState(detailsForm);
      const [ addressFormData, setAddressForm ] = useState(addressForm);
      const [ sameBillingAddress, changeSameBillingAddress ] = useState(true);

      const { firstName, lastName, email, phone } = detailsFormData;
      const { line1, line2, town, county, postcode, country } = addressFormData;
      const [tabIndex, setTabIndex] = useState(0);

      const onChange = e =>{
        setDetailsForm({ ...detailsFormData, [e.target.name]: e.target.value });}

      const onAddressChange = e =>{
        setAddressForm({ ...addressFormData, [e.target.name]: e.target.value });}

      const onBillingChecked = e =>{
        changeSameBillingAddress(e.target.checked)
      }


      const fireCreateOrder = () => {
        const order = {
          deliveryAddress: addressFormData,
          products: cartItems,
          delivery: postage
          }
          setTabIndex(2);
          createOrder(order);
          togglePayment();
        }

      const fireCollectOrder = (e) => {
        e.preventDefault();
        const order = {
          deliveryAddress: {
            line1: 'Inverurie Fireworks Store',
            line2: 'Unit 7, Blackhall Industrial Estate',
            town: 'Inverurie',
            county: 'Aberdeenshire',
            postcode: 'AB51 4FS'
          },
          products: cartItems,
          delivery: postage
        }
        createOrder(order);
        togglePayment();
      }

        return (
            <section className="tab-product m-0">
                <div className="row">
                    <div className="col-sm-12 col-lg-12">
                        <Tabs className="tab-content nav-material" selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                            <TabList className="nav nav-tabs nav-material">
                                <Tab className="nav-item">
                                    <span className="nav-link">
                                        <i className="icofont icofont-ui-home"></i>Your Details</span>
                                    <div className="material-border"></div>
                                </Tab>
                                {postage === 'delivery' ? <Tab className="nav-item">
                                    <span className="nav-link" ><i className="icofont icofont-man-in-glasses"></i>Delivery Address</span>
                                    <div className="material-border"></div>
                                </Tab> : ''}

                                <Tab className="nav-item" disabled={true}>
                                    <span className="nav-link" >
                                        <i className="icofont icofont-contacts"></i>Confirm Order</span>
                                    <div className="material-border"></div>
                                </Tab>
                            </TabList>

                            <TabPanel>
                            <br/>
                            <div className="checkout-title">
                                <h3>Your Details</h3>
                            </div>
                            <form onSubmit={e => {
                              submitDetails(detailsFormData);
                              let tab;
                              postage === 'delivery' ? tab = 1 : tab = 2;
                              setTabIndex(tab)
                            }}>
                            <div className="row check-out">

                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                    <div className="field-label">First Name</div>
                                    <input type="text" name="firstName" value={firstName} onChange={e => onChange(e)} required />
                                </div>
                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                    <div className="field-label">Last Name</div>
                                    <input type="text" name="lastName" value={lastName} onChange={e => onChange(e)} />
                                </div>
                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                    <div className="field-label">Phone</div>
                                    <input type="text" name="phone"  value={phone} onChange={e => onChange(e)} />
                                </div>
                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                    <div className="field-label">Email Address</div>
                                    {(isAuthenticated) ?
                                      <input type="text" name="email" value={email} onChange={e => onChange(e)} disabled /> :
                                    <input type="text" name="email" value={email} onChange={e => onChange(e)} required />}
                                </div>

                                <div className="form-group col-md-6 col-sm-6 col-xs-12">

                                    <button type="submit" className="btn-solid btn" >Next</button>

                                </div>


                              </div>
                              </form>
                            </TabPanel>

                            <TabPanel className="tab-pane fade mt-4 show active">
                              <form>
                              <div className="checkout-title">
                                  <h3>Delivery Address</h3>
                              </div>
                              <div className="row check-out">


                                  <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                      <div className="field-label">Address</div>
                                      <input type="text" name="line1" value={line1} onChange={e => onAddressChange(e)} placeholder="Street address" />
                                  </div>
                                  <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                      <div className="field-label">Address Line 2</div>
                                      <input type="text" name="line2" value={line2} onChange={e => onAddressChange(e)} placeholder="Street address" />
                                  </div>
                                  <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                      <div className="field-label">Town/City</div>
                                      <input type="text" name="town" value={town} onChange={e => onAddressChange(e)} />
                                  </div>
                                  <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                      <div className="field-label">Region</div>
                                      <input type="text" name="county" value={county} onChange={e => onAddressChange(e)} />
                                  </div>
                                  <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                      <div className="field-label">Postal Code</div>
                                      <input type="text" name="postcode" value={postcode} onChange={e => onAddressChange(e)} />
                                  </div>

                                  <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <input type="checkbox" name="billing_address_checkbox" id="billing-option"  checked={sameBillingAddress} onChange={e => onBillingChecked(e)}/>
                                                        &ensp; <label htmlFor="billingOption">Use this address for billing?</label>
                                                    </div>

                                  <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <button type="submit" className="btn-solid btn" onClick={fireCreateOrder}>Submit Details</button>
                                  </div>
                              </div>



                              </form>
                            </TabPanel>

                            <TabPanel>
                                {postage === 'delivery' ? <div className="mt-4 text-center">
                                <div className="checkout-title">
                                    <h3>Order Details</h3>
                                </div>
                                <div>
                                  <h4>Delivery Address</h4>
                                  <p>{firstName} {lastName}</p>
                                  <p>{line1}</p>
                                  <p>{line2}</p>
                                  <p>{town}</p>
                                  <p>{county}</p>
                                  <p>{postcode}</p>
                                </div>
                                <div>
                                <br/>
                                  <h4>Billing Address</h4>
                                  {sameBillingAddress ?
                                    <div>
                                    <p>{line1}</p>
                                    <p>{line2}</p>
                                    <p>{town}</p>
                                    <p>{county}</p>
                                    <p>{postcode}</p>
                                    </div> :
                                    <p>Your billing address will be collectd by the payment processor</p>
                                  }
                                  <br/>
                                </div>
                                </div> :
                                <div className="mt-4 text-center">
                                  <div className="checkout-title">
                                  <h3>Order Details</h3>
                                  <h5>{firstName} {lastName}</h5>
                                  </div>
                                  <div>
                                    <h4>Collect in Store</h4>
                                    <p>Inverurie Fireworks Store</p>
                                    <p>Unit 7</p>
                                    <p>Blackhall Industrial Estate</p>
                                    <p>Inverurie</p>
                                    <p>AB51 4FS</p>
                                  </div>
                                  <br/>
                                  <div>
                                  <button className="btn-solid btn" onClick={e => fireCollectOrder(e)}>Confirm Collection</button>
                                  </div>
                                  <br/>
                                </div>
                              }
                            </TabPanel>

                        </Tabs>
                    </div>
                </div>
            </section>
        )

}

TabbedForm.propTypes = {
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object.isRequired,
  submitDetails: PropTypes.func.isRequired,
  createOrder: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  cartItems: state.cartList.cart
});

export default connect(mapStateToProps, {submitDetails, createOrder})(TabbedForm);
